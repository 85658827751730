import { Box, Typography } from "@mui/material";

const SectionHeading = ({ title, color = 'primary' }) => {
    return (
        <Box bgcolor="#0D46A0" px={2} py={1}>
            <Typography variant="subtitle2" color={color}>
                {title}
            </Typography>
        </Box>
    );
};

export default SectionHeading;
