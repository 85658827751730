import { Box, Button, MenuItem, TextField } from "@mui/material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import SearchContainer from "components/SearchContainer";
import { useContext, useState } from "react";
import Actions from "./Actions";
import { useMutation, useQuery } from "react-query";
import { handleError } from "utils/handleError";
import { snack } from "components/toast";
import { exportReceipts } from "api/services/billing/receipts";

interface IProps {
  selected: any[];
  search: string;
  setSearch: (v: string) => void;
  clearSelection: () => void;
}

function ReceiptsHeader(props: IProps) {
  const { selected, search, setSearch, clearSelection } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mutate, isLoading } = useMutation(exportReceipts, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const pdf = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = pdf;
      link.download = "Receipts.xlsx";
      link.click();
      snack.success("Receipt(s) List Exported to Excel");
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    const data = { search,};
    mutate(data);
  };

  return (
    <Box
      mb={2}
      pr={2}
      pl={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <SearchContainer debounced placeHolder="Search by Client Name | Receipt #" value={search} onChange={setSearch} />
      <Box display="flex" gap={1}>
        <Button
          endIcon={<ArrowDropDownOutlinedIcon />}
          color="primary"
          variant="outlined"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Actions
        </Button>
        {/* <Button variant="outlined" color="secondary" onClick={handleExport}
            startIcon={<FileDownloadOutlinedIcon />}     >
            Export
          </Button> */}
      </Box>
      <Actions
        clearSelection={clearSelection}
        selected={selected}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  );
}

export default ReceiptsHeader;
