import { Box, Breadcrumbs, IconButton, Tooltip, Typography } from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { formatToRupeess } from "utils/formattedAmount";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClientsHeader from "views/atom-pro/ClientsHeader";
import { LinkRouter } from "components/BreadCrumbs";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import {
  getClientproceedingFya,
} from "api/services/automation/income-tax";

const ClientEproceedingFya = (tab) => {
  const [active, setActive] = useState("FYA");
  useTitle("ATOM Pro | Clients");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  //   const { data, isLoading }: ResType = useQuery(
  //     ["e-proceedings", {
  //         search: search,
  //         limit: pageCount,
  //         offset: page * pageCount,
  //         tab:tab.tab
  //     }], getIncomeTaxEproceedings, {
  //     onSuccess: (res) => {
  //         setTotalCount(res?.data?.count)
  //     }
  // }
  // );

  const { data, isLoading, error }: ResType = useQuery(
    ["client", params.incometaxid, { search, limit: pageCount, offset: page * pageCount }],
    getClientproceedingFya,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );



  return (
    <>

      <Box p={2}>
        {/* <ClientsHeader search={search} setSearch={setSearch} /> */}
        <Table
          sx={{ height: "480px" }}
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data?.result || []}
          columns={columns}
          loading={isLoading}
        // onRowClick={handleRowClick}
        />
      </Box>
    </>
  );
};

function generateRandomColor(index) {
  const colors = ["#ff9595", "#EDE7FB", "#FBE7E9", "#FCF4C5", "#EEFCC9", "#F6F6F6", "#EAFFE7"];
  const colorIndex = index % colors.length;
  return colors[colorIndex];
}

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

const columns: Array<ColumnType> = [

  {
    key: "assesmentYear",
    title: "Assessment Year",
    render: (v) => (
      <Typography
      // sx={{
      //   backgroundColor: "#000058",
      //   display: "inline-block",
      //   padding: "2px 5px",
      //   border: "1px solid #bb9ce7",
      //   borderRadius: "4px",
      //   color: "#FFFFFF",
      //   fontSize: "12px",
      // }}
      >
        {generateAssessmentYear(v?.assesmentYear)}
      </Typography>
    ),
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Name",
    render: (v) => (
      <Typography
      // sx={{
      //   display: "inline-block",
      //   padding: "0px 5px",

      //   color: "#222222",
      //   fontSize: "12px",
      // }}
      >
        {v.proceedingName}
      </Typography>
    ),
  },
  {
    key: "noticeName",
    title: (
      <Box display="flex" alignItems="center">
        <FormatListBulletedIcon style={{ width: "20px", marginRight: "5px" }} /> Sections
      </Box>
    ),
    render: (v) =>
      v?.noticeName ? (
        <Typography
        // sx={{
        //   color: "#222222",
        // }}
        // variant="body2"
        >
          <span
          // style={{
          //   backgroundColor: generateRandomColor(v.id),
          //   display: "inline-block",
          //   padding: "0px 5px",
          //   border: "1px solid #EDE7FB",
          //   borderRadius: "4px",
          //   color: "#222222",
          //   fontSize: "12px",
          //   marginRight: "5px",
          // }}
          >
            {" "}
            {v.noticeName}
          </span>
        </Typography>
      ) : (
        "--"
      ),
  },

  {
    key: "",
    title: "Actions",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-action/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default ClientEproceedingFya;
