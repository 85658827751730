import { Grid, Typography } from "@mui/material";
import { getClientBillingAnalytics } from "api/services/organization";
import Loader from "components/Loader";
import { useQuery } from "react-query";
import { ResType } from "types";
import ClientDetailsSection from "./clientdetailssection";
import { StyledTaskBox } from "pages/styles";
import { formattedAmount } from "utils/formattedAmount";

function ClientDashboardAnalytics() {
  const clientId = localStorage.getItem('clientId');

  const { data, isLoading }: ResType = useQuery(
    ["client-analytics", { clientId: clientId }],
    getClientBillingAnalytics,
  );

  if (isLoading) return <Loader />;

  return (
    <Grid container display='flex' flexDirection="row" spacing={2} xs={12} height={100}>
      <Grid item xs={5} >
        <ClientDetailsSection />
      </Grid>
      <Grid item xs={7} spacing={1} container display='flex' flexDirection='row' height="100%">
        <Grid item xs={6} md={4}>
          <StyleBox result={data?.data?.nonbillabletasks} title={"Non-Billable Tasks"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <StyleBox result={data?.data?.billabletasks} title={"Billable Tasks"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <StyleBox result={data?.data?.totaltasks} title={"Total Tasks"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <StyleBox result={data?.data?.billedtasks} title={"Billed Tasks"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <StyleBox result={data?.data?.unbilledtasks} title={"Un-Billed Tasks"} />
        </Grid>
        <Grid item xs={6} md={4}>
          <StyleBox result={formattedAmount(data?.data?.credits)} title={"Available Credits"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data?.pureagent)} title={"Pure Agent"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data?.pureagentreceived)} title={"Pure Agent Received"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data?.pureagentdue)} title={"Pure Agent Due"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data.serviceamount)} title={"Service Amount"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data?.serviceamountreceived)} title={"Service Amount Received"} />
        </Grid>
        <Grid item xs={4}>
          <StyleBox result={formattedAmount(data?.data?.serviceamountdue)} title={"Service Amount Due"} />
        </Grid>
      </Grid>
    </Grid>
  )
}


function StyleBox({ result, title }) {

  return (
    <StyledTaskBox sx={{ width: "160px", height: "120px" }}>
      <header>
        <Typography variant="h6">{title}</Typography>
      </header>
      <main>
        {" "}
        <Typography variant="h6">{(result)}</Typography>
      </main>
    </StyledTaskBox>
  );
}

export default ClientDashboardAnalytics;