import { Box, MenuItem, TextField, Typography } from "@mui/material"
import { getIncomeTaxReports } from "api/services/automation/income-tax";
import { useQuery } from "react-query";
import { ResType } from "types";
import Table from "components/Table";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useTitle from "hooks/useTitle";

const IncomeTaxReports = () => {
    useTitle("ATOM Pro | Reports");
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selected, setSelected] = useState<any[]>([]);
    const [filters, setFilters] = useState<any>({
        status: null,
        remarks: null,
    });

    const { data, isLoading }: ResType = useQuery(
        ["Income-tax-reports", { limit: pageCount, offset: page * pageCount, ...filters }],
        getIncomeTaxReports, {
        onSuccess: (res) => {
            setTotalCount(res?.data?.count)
        }
    }

    );


    let columns = [
        {
            key: "id",
            title: "Client Name",
            render: (rowData: any) => <Typography>{rowData?.autoCredentials?.client?.displayName}</Typography>,
        },
        {
            key: "id",
            title: "PAN",
            render: (rowData: any) => <Typography>{rowData?.autoCredentials?.panNumber}</Typography>,
        },
        {
            key: "status",
            title: "Status",
        },
        {
            key: "remarks",
            title: "Remarks",
            width: "250px"
        },
        {
            key: "createdAt",
            title: "Last Sync",
            render: (row) => {
                return moment(row?.createdAt).format("DD-MM-YYYY h:mm a");
            },
        },

    ];

    const navigate = useNavigate()
    const handleRowClick = (v: any) => {
        navigate(`/atom-pro/income-tax/incometax/${v?.autoCredentials?.id}/client-status`)
    }

    const statusObject = {
        PENDING: "Pending",
        COMPLETED: "Completed",
        INQUEUE: "Inqueue"
    }

    const remarksObject = {
        "Success": "Success",
        "Invalid Password, Please retry.": "Invalid Password",
        "PAN does not exist, Please register this PAN or try with some other PAN.": "PAN does not exist",
        "Login attempt was unsuccessful due to Mult-Factor Authentication": "Mult-Factor Authentication",

    }

    const handleStatusChange = (e: any) => {
        setFilters({ ...filters, status: e.target.value });
        setPage(0);
    };

    const handleRemarksChange = (e: any) => {
        setFilters({ ...filters, remarks: e.target.value });
        setPage(0);
    };
    return (
        <Box m={1}>
            <Box display="flex">
                <Box ml={1}>
                    <TextField
                        label="Status"
                        value={filters.status || ""}
                        sx={{ width: 150 }}
                        onChange={handleStatusChange}
                        size="small"
                        select
                    >
                        <MenuItem key={""} value={""}>
                            Select
                        </MenuItem>
                        {Object.keys(statusObject).map((key, index) => (
                            <MenuItem key={index} value={key}>
                                {statusObject[key]}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box ml={1}>
                    <TextField
                        label="Remarks"
                        value={filters.remarks || ""}
                        sx={{ width: 200 }}
                        onChange={handleRemarksChange}
                        size="small"
                        select
                    >
                        <MenuItem key={""} value={""}>
                            Select
                        </MenuItem>
                        {Object.keys(remarksObject).map((key, index) => (
                            <MenuItem key={index} value={key}>
                                {remarksObject[key]}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            </Box>
            <Box mt={1}>
                <Table
                    sx={{ height: "550px" }}
                    pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                    data={data?.data?.data || []}
                    columns={columns}
                    onRowClick={handleRowClick}
                    loading={isLoading}
                />
            </Box>
        </Box>
    )


}

export default IncomeTaxReports