import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { getCommonBilledTaskexport, getCommonBilling } from "api/services/reports";
import SearchContainer from "components/SearchContainer";
import Table, { ColumnType } from "components/Table";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import _ from "lodash";
import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { formattedAmount } from "utils/formattedAmount";
import AccessDenied from "../invoices/AccressDenied";
import { UserProfileContext } from "context/UserProfile";

function BilledTasks() {
  useTitle("Clients");
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState();
  const [pageCount, setPageCount] = useState<number>(10);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const clientId = localStorage.getItem('clientId');

  const { data: result, isLoading, error }: ResType = useQuery(
    ['clientinvoicebilled', {
      query: 'clientinvoicebilled',
      clientId: clientId,
      search,
      pageCount: pageCount,
      offset: page * pageCount
    }],
    getCommonBilling, {
    onSuccess: (res: any) => {
      setData(res?.data);
      setTotalCount(res?.data[0].total_count);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    }
  });

  const setSearchChange = (e) => {
    setSearch(e);
    setPage(0);
  };

  const { mutate:mutate1 } = useMutation(getCommonBilledTaskexport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "client-billed-Tasks.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  
  const handleExport = () => {
    const data = {
      query: 'clientinvoicebilled',
      clientId: clientId, search,

    }
    mutate1(data);
  }


  return (
    <Box>            
        <Box display="flex" gap={3} justifyContent="space-between" mt={2} mr={2} mb={1}>
        <Box display="flex" flex={1} gap={2} alignItems="center">
          <SearchContainer placeHolder="Search by Task ID | Task Name" value={search} onChange={setSearchChange} debounced />
          {/* <Button variant="outlined" color="secondary" onClick={handleExport}
            startIcon={<FileDownloadOutlinedIcon />}     >
            Export
          </Button> */}
        </Box>

      </Box>
      <Box sx={{ mt: 2 }}>
        <Table
          sx={{ mt: 2, mb: 0, pb: 0 }}
          loading={isLoading}
          data={data || []}
          columns={Columns}
          pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
          onRowClick={(row) => navigate(`/tasks/${row.id}#details`)}
        />
      </Box>

    </Box>
  );
}

const Columns: Array<ColumnType> = [
  { key: "tasknumber", title: "Task ID" },
  { key: "name", title: "Task Name", width: "250px" },
  {
    key: "status", title: "Task Status", render: (row: any) => {
      const words = row.status.split('_');
      const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
      return titleCaseWords.join(' ');
    },
  },
  { key: "invoice_date", title: "Invoice Date" },
  { key: "invoice_due_date", title: "Due Date" },
  { key: "amount", title: "Invoice Amount", render: (row) => (formattedAmount(row?.amount)) },
];

export default BilledTasks;
