import { http } from "api/http";

const getClients = ({ queryKey }: any) => {
  let { limit, offset, query } = queryKey[1] || {};
  return http.get("/client", {
    params: {
      limit,
      offset,
      ...query,
    },
  });
};

const getClientPortalClients = ({ queryKey }: any) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/clients", {
    params: {
      limit,
      offset,
      ...query,
    },
  });
};

const getAllClients = ({ queryKey }: any) => {
  return http.get("/client/all")
};

const getClient = ({ queryKey }: any) => {
  return http.get(`/client/${queryKey[1]}/details`);
};

const getLeads = ({ queryKey }) => {
  return http.get("/leads", { params: { ...queryKey[1] } });
};

const getCompletedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/completed-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getClientCompletedTasksExport = (data) => {
  return http.post(`/tasks/completed-tasks-export`, data);
};

const getUserCompletedTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get(`/tasks/user-completed-tasks`, {
    params: {
      userId: queryKey[1].userId,
      limit,
      offset,
      ...query
    },
  });
};

const getDeletedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/deleted-tasks`, {
    params: {
      client: queryKey[1]?.client,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getClientDeletedTasksExport = (senddata) => {
  return http.post(`/tasks/deleted-tasks-export`, senddata);
};

const getTerminatedTasksList = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get(`/tasks/terminatedtaskslist`, {
    params: {
      clientId: queryKey[1]?.clientId,
      limit,
      offset,
      ...query,
    },
  });
};

const getTerminatedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/terminated-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getClientTerminatedTasksexport = (senddata) => {
const data = senddata
  return http.post(`/tasks/terminated-tasks-export`,data );
};

const getDeletedClients = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/client/deleted", {
    params: {
      limit,
      offset,
      ...query,
    },
  });
};

export {
  getClients,
  getClient,
  getLeads,
  getTerminatedTasks,
  getUserCompletedTasks,
  getCompletedTasks,
  getDeletedTasks,
  getDeletedClients,
  getAllClients,
  getTerminatedTasksList,
  getClientCompletedTasksExport,
  getClientTerminatedTasksexport,
  getClientDeletedTasksExport,
  getClientPortalClients,
};