import { CalendarMonthOutlined } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { Box, Typography, IconButton, Popover, TextField, Button } from "@mui/material";
import { FromToDates } from "data/constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { handleKeyDown } from "utils/keyDownPrevent";
import "pages/GridStyling.css";


function DateRange({ dates: datesProp, setDates: setDatesProp }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [dates, setDates] = useState<any>({ fromDate: null, toDate: null });
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<any[]>([false, false]);
  const [forFocus, setForFocus] = useState<Boolean[]>([true, true]);

  const handleSelect = (type: FromToDates) => {
    const newIsDatePickerOpen = [...isDatePickerOpen];
    const newForFocus = [...forFocus];

    if (type === FromToDates.FROM_DATE) {
      newIsDatePickerOpen[0] = false;
      newForFocus[0] = false;
    } else if (type === FromToDates.TO_DATE) {
      newIsDatePickerOpen[1] = false;
      newForFocus[1] = false;
    }

    setIsDatePickerOpen(newIsDatePickerOpen);
    setForFocus(newForFocus);
  };

  const setIsDatePickerOpenHandle = (type: FromToDates) => {
    const newIsDatePickerOpenDemo = [...isDatePickerOpen];
    if (type === FromToDates.FROM_DATE) {
      newIsDatePickerOpenDemo[0] = true;
    } else if (type === FromToDates.TO_DATE) {
      newIsDatePickerOpenDemo[1] = true;
    }
    setIsDatePickerOpen(newIsDatePickerOpenDemo);

  };

  const handleFocus = (type: FromToDates) => {
    if ((type === FromToDates.FROM_DATE && forFocus[0]) || (type === FromToDates.TO_DATE && forFocus[1])) {
      const newIsDatePickerOpen = [...isDatePickerOpen];
      newIsDatePickerOpen[type === FromToDates.FROM_DATE ? 0 : 1] = true;
      setIsDatePickerOpen(newIsDatePickerOpen);

      const newForFocus = [...forFocus];
      newForFocus[type === FromToDates.FROM_DATE ? 0 : 1] = true;
      setForFocus(newForFocus);
    }
  };

  useEffect(() => {
    setDates(datesProp);
  }, [datesProp]);

  const handleApply = () => {
    if (!dates.fromDate || !dates.toDate) return;
    setDatesProp(dates);
    setAnchorEl(null);
  };

  const handleClear = () => {
    setDates({ fromDate: null, toDate: null });
    setDatesProp({ fromDate: null, toDate: null });
    setAnchorEl(null);
  };

  return (
    <>
         <Box className="daterange">

        {datesProp.fromDate && datesProp.toDate ? (
          <Typography variant="body2">
            {moment(datesProp.fromDate).format("DD/MM/YYYY")} -{" "}
            {moment(datesProp.toDate).format("DD/MM/YYYY")}
          </Typography>
        ) : (
          <Typography variant="body2">Date Range</Typography>
        )}
        <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <CalendarMonthOutlined fontSize="small" />
        </IconButton>
      </Box>
      <Popover
        PaperProps={{
          sx: {
            width: 350,
            py: 4,
            px: 2,
            mt: 1,
            mr: 1,
          },
        }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >

        <DatePicker
          label="From Date"
          inputFormat="dd-MM-yyyy"
          value={dates.fromDate}
          // maxDate={dates.toDate|new Date()} // this line was added to freeze dates after today
          maxDate={dates?.toDate ? dates.toDate : new Date()}
          onChange={(value) => {
            setDates({
              ...dates,
              fromDate: value,
            });
            handleSelect(FromToDates.FROM_DATE);
          }}
          open={isDatePickerOpen[0]}

          renderInput={(params) => <TextField
            fullWidth
            size="small"
            onKeyDown={handleKeyDown}
            onClick={() => { setIsDatePickerOpenHandle(FromToDates.FROM_DATE); }}
            onFocus={() => handleFocus(FromToDates.FROM_DATE)}
            autoComplete="off"
            {...params} />}
        />

        <DatePicker
          label="To Date"
          inputFormat="dd-MM-yyyy"
          value={dates.toDate}
          minDate={dates.fromDate}
          maxDate={new Date()}
          onChange={(value) => {
            setDates({
              ...dates,
              toDate: value,
            });
            handleSelect(FromToDates.TO_DATE);
          }}
          open={isDatePickerOpen[1]}
          renderInput={(params) => <TextField
            sx={{ mt: 2 }}
            fullWidth
            size="small"
            {...params}
            onKeyDown={handleKeyDown}
            onClick={() => { setIsDatePickerOpenHandle(FromToDates.TO_DATE); }}
            onFocus={() => handleFocus(FromToDates.TO_DATE)}
            autoComplete="off"
          />}
        />
        <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
          <Button color="secondary" variant="outlined" size="small" onClick={handleClear}>
            Reset
          </Button>
          <Button color="secondary" variant="outlined" size="small" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default DateRange;
