import { Box, Grid, Typography, Stepper, Step, StepLabel, Divider, } from "@mui/material"
import { getReturnData } from "api/services/automation/income-tax";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";

import { styled } from "@mui/system";
import Loader from "components/Loader";
import { useState } from "react";
import { getTitle } from "utils";
import moment from "moment";
import StepIcon from '@mui/material/StepIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { calculateAssessmentYear } from "pages/atom-pro/constants";


interface StyledSpanProps {
    bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
    backgroundColor: bgColor || "#ffffff",
    padding: "5px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "5px",
}));

const ReturnFullView = () => {
    const params = useParams();
    const [profileData, setProfileData] = useState<any>("")

    const { data, isLoading }: ResType = useQuery(["get form data", params.id], getReturnData, {
        onSuccess: (res: any) => {
            if (res?.data) {
                setProfileData(res?.data)
            }

        },
    });
    console.log({ profileData })
    const calculateXs = (value: string) => {
        const length = value.toString().length;
        if (length <= 25) {
            return 3;
        }
        // else if (length <= 50) {
        //     return 4;
        // } 
        else if (length <= 100) {
            return 6;
        } else {
            return 12;
        }
    };

    const backgroundColors = ["#ffffcc", "#ccffcc", "#ffcccc", "#ccccff", "#ffccff", "#f0f8ff", "#f0fff0", "#f5f5dc", "#f5f5f5", "#f5fffa"];
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * backgroundColors.length);
        return backgroundColors[randomIndex];
    };

    const getFileName = (filePath, type) => {
        // Split the filePath by '/' and get the last part which is the file name
        if (type == 'AMAZON' || type == null) {
            const parts = filePath.split('/');
            return parts[parts.length - 1];
        } else if (type == 'MICROSOFT') {
            const parts = filePath['name'].split('/');
            return parts[parts.length - 1];
        };
        return null

    };

    const handleItemClick = (fileName, type) => {
        const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
        const amazon = type == "AMAZON" || type == null
        const url = amazon ? (urlStart + fileName) : fileName?.key;
        window.open(url, '_blank');
    };

    const getFilingType = (type) => {
        const filingTypes = {
            "O": "Original Return",
            "D": "Defective Return",
            "R": "Revised Return",
            "T": "Rectification Return",
            "U": "Updated Return"
        };
        const abc = filingTypes[type]
        console.log("ooooooooooo", abc)
        return abc
    }

    const getincmTaxSec = (sec) => {
        const filingSections = {
            "11": "139(1)",
            "12": "139(4)",
            "13": "",
            "14": "Return filed in response to notice under section 148",
            "15": "",
            "16": "",
            "17": "139(5)",
            "18": "139(9)",
            "19": "92CD",
            "20": "119(2)(b)",
            "21": "139(8A)",
            "null": "-"
        };
        const abc = filingSections[`${sec}`]
        console.log("iiii", abc)
        return abc
    }

    const getformType = (type) => {
        console.log(typeof type, type)
        const itrTypes = {
            "1": "ITR-1",
            "2": "ITR-2",
            "2A": "ITR-2A",
            "3": "ITR-3",
            "4": "ITR-4",
            "4S": "ITR-4S",
            "5": "ITR-5",
            "6": "ITR-6",
            "7": "ITR-7"
        };

        const abc = itrTypes[type]
        console.log("iii888888888i", abc)
        return abc
    }

    const formattedRefundAmt = profileData["refundAmt"] ?
        Number(profileData["refundAmt"]).toLocaleString('en-IN') : "";


    const formattedDemandAmt = profileData["demandAmt"] ?
        Number(profileData["demandAmt"]).toLocaleString('en-IN') : "";





    if (isLoading) return <Loader />
    return (
        <Box m={2} height="70vh" overflow="auto">
            <Typography my={2} variant="h5" color="primary">Returns Details</Typography>
            <Grid container spacing={2}>

                <Grid item xs={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={3} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Assessment Year</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {calculateAssessmentYear(profileData["assmentYear"])}
                                </StyledSpan>
                            </Box>
                        </Grid>
                        <Grid item xs={9} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Client Name</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {profileData?.client?.autProfileDetails?.[0]?.name}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Filing Type</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {getFilingType(profileData["filingTypeCd"])}
                                </StyledSpan>
                            </Box>
                        </Grid>
                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Form Type</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {getformType(profileData["formtypeCd"])}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Income Tax Section</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {getincmTaxSec(profileData["incmtaxSecCd"])}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Acknowledgement #</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {profileData["ackNum"]}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Acknowledgement Date</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {moment(profileData["ackDt"]).format("DD-MM-YYYY")}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Submited By User ID</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {profileData["submitUserId"]}
                                </StyledSpan>
                            </Box>
                        </Grid>



                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Verification Status</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    Verified
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Verification Date</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {moment(profileData["verDt"]).format("DD-MM-YYYY")}
                                </StyledSpan>
                            </Box>
                        </Grid>
                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Submit By</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    {profileData["submitBy"]}
                                </StyledSpan>
                            </Box>
                        </Grid>
                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Refund Amount (₹)</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    ₹  {formattedRefundAmt}
                                </StyledSpan>
                            </Box>
                        </Grid>

                        <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Demand Amount (₹)</Typography>
                                <StyledSpan
                                // bgColor={getRandomColor()}
                                >
                                    ₹ {formattedDemandAmt}

                                </StyledSpan>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={4} >
                            <Box
                                style={{
                                    border: "1.5px solid #d1d1e0",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    borderRadius: "7px"
                                }}
                            >
                                <Typography variant="h6" color="primary" mb={1}>Filing Date</Typography>
                                <StyledSpan 
                                // bgColor={getRandomColor()}
                                >
                                    {moment(profileData["submitTmstmp"]).format("DD-MM-YYYY")}
                                </StyledSpan>
                            </Box>
                        </Grid> */}

                    </Grid>
                    <Divider />
                    <Grid container spacing={2}>
                        <Grid item >
                            <Typography variant="h6" color="primary">Attachments</Typography>
                            {profileData.storageFiles && profileData.storageFiles.map((detail: any, index: number) => (
                                <Box display="flex" textAlign="center">
                                    <Typography style={{ padding: '5px', margin: '5px' }}>{index + 1}.</Typography>
                                    <div
                                        key={index}
                                        style={{ cursor: 'pointer', padding: '5px', margin: '5px', border: '1px solid #ccc', borderRadius: '5px', color: "blue" }}
                                        onClick={() => handleItemClick(detail, profileData.storageSystem)}
                                    >
                                        {getFileName(detail, profileData.storageSystem)}
                                    </div>
                                </Box>

                            ))}

                        </Grid>
                    </Grid>
                </Grid>


                {/* <Grid item xs={7}>
                    <Grid container spacing={2}>
                        {Object.keys(profileData).map((key: any) => (
                            // Check if the value is not empty before rendering
                            <>
                                {key !== "itrPanDetlList" && key !== "client" && key !== "storageFiles" && (
                                    <Grid item xs={calculateXs(profileData[key])} key={key}>
                                        <Box
                                            style={{
                                                border: "1.5px solid #d1d1e0",
                                                padding: "10px",
                                                marginBottom: "10px",
                                                borderRadius: "7px"
                                            }}
                                        >
                                            <Typography variant="h6" color="primary" mb={1}>{getTitle(key)}</Typography>
                                            <StyledSpan 
                                            // bgColor={getRandomColor()}
                                            >
                                                {profileData[key]}
                                            </StyledSpan>
                                        </Box>
                                    </Grid>
                                )}

                            </>
                        ))}
                    </Grid>
                </Grid> */}
                <Grid item xs={5}>
                    <Stepper orientation="vertical">
                        {profileData.itrPanDetlList && profileData.itrPanDetlList.map((detail: any, index: number) => (
                            <Step key={index}>
                                <StepLabel style={{ color: "green" }}
                                    StepIconComponent={() => (
                                        <CheckCircleOutlineIcon />
                                    )}
                                >
                                    <Typography variant="body1">{detail?.statusDesc}</Typography>
                                    <Typography variant="body2">{moment(detail?.activityDt).format("DD-MM-YYYY")}</Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>

            </Grid>

        </Box>
    )
}

export default ReturnFullView