import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Box, Button, Typography } from "@mui/material";
import { getCommonBilling, getCommonReceiptsexport } from "api/services/reports";
import Table, { ColumnType } from "components/Table";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import _ from "lodash";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import { getReceiptColor } from "views/billing/estimates/getStatusColor";
import { capitalizeWords } from "utils";
import { formattedAmount } from "utils/formattedAmount";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AccessDenied from "../invoices/AccressDenied";
import { UserProfileContext } from "context/UserProfile";


function ClientReceipts() {
  useTitle("Clients");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState();
  const [pageCount, setPageCount] = useState<number>(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const clientId = localStorage.getItem('clientId');

  const { data: result, isLoading, error }: ResType = useQuery(
    ['clientinvoicereceipts', {
      query: 'clientinvoicereceipts',
      clientId: clientId, search,
      pageCount: pageCount,
      offset: page * pageCount
    }],
    getCommonBilling, {
    onSuccess: (res: any) => {
      setData(res?.data);
      setTotalCount(res?.data[0].total_count);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    }
  });

  const { mutate: mutate1 } = useMutation(getCommonReceiptsexport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "client-receipts-Tasks.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    const data = {
      query: 'clientinvoicereceipts',
      clientId: clientId, search,
    }
    mutate1(data);
  }

  return (
    <>
      {/* <SearchContainer value={search} onChange={setSearch} debounced /> */}
      {/* <Box display="flex" justifyContent="flex-end" marginRight={2}>
        <Button variant="outlined" color="secondary" onClick={handleExport}
          startIcon={<FileDownloadOutlinedIcon />}     >
          Export
        </Button>
      </Box> */}
      <Box display="flex" flex={1} gap={2} alignItems="center">
        <Box display="flex" gap={2}>
          {selected.length > 0 && (
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              variant="outlined"
              color="secondary"
              endIcon={<KeyboardArrowDownOutlinedIcon />}
            >
              Actions
            </Button>
          )}
        </Box>
      </Box>
      <Table
        sx={{ mt: 2, pb: 0, mb: 0 }}
        loading={isLoading}
        data={data || []}
        columns={Columns}
        pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
        onRowClick={(row) => window.open(`/billing/receipts/${row?.id}/preview`)}
      />
    </>
  );
}

const Columns: Array<ColumnType> = [
  { key: 'trade_name', title: 'Billing Entity' },
  { key: "receipt_date", title: "Receipt Date" },
  { key: "receipt_number", title: "Receipt #" },
  {
    key: "type", title: "Receipt Type",
    render: (row: any) => capitalizeWords(row.type == 'INVOICE' ? "Payment Receipt" : "Advance"),
  },
  {
    key: "amount", title: "Amount (₹)", render: (row: any) => (
      formattedAmount((row?.amount * 1)))
  },
  {
    key: "status", title: "Status",
    render: (row: any) => (
      <Box
        sx={{
          background: getReceiptColor(row?.status),
          px: 2,
          py: "4px",
          color: "white",
          borderRadius: "10px",
          textAlign: "center",
          display: "inline-flex",
        }}
      >
        <Typography variant="body2">
          {capitalizeWords(row?.status)}
        </Typography>
      </Box>
    ),
  },
];

export default ClientReceipts;
