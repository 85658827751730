import { http } from "api/http";

const getClientProfile = () => {
  return http.get("/client-profile");
};

const getEachClientProfile = ({ queryKey }) => {
  return http.get("/client-profile-single", { params: { ...queryKey[1] } });
};

const getClientCredentials = ({ queryKey }) => {
  return http.get("credentials", { params: { ...queryKey[1] } });
};

const getClientKybForms = ({ queryKey }) => {
  return http.get("/kyb", { params: { ...queryKey[1] } });
};

const getDscRegisters = ({ queryKey }) => {
  return http.get("/dsc-register", { params: { ...queryKey[1] } });
};

const getRecurringProfiles = ({ queryKey }: any) => {
  return http.get("/recurring-profile", { params: { clientId: queryKey[1] } });
};

const getCompletedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/completed-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getTerminatedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/terminated-tasks`, {
    params: {
      clientId: queryKey[1].clientId,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getDeletedTasks = ({ queryKey }) => {
  let { limit, offset, query, search } = queryKey[1] || {};

  return http.get(`/tasks/deleted-tasks`, {
    params: {
      client: queryKey[1]?.client,
      limit,
      offset,
      search,
      ...query,
    },
  });
};

const getDscRegister = ({ queryKey }) => {
  return http.get(`/dsc-register/${queryKey[1]?.id}/details`, { params: { ...queryKey[1] } });
};

export {
  getClientProfile,
  getClientCredentials,
  getClientKybForms,
  getDscRegisters,
  getRecurringProfiles,
  getCompletedTasks,
  getTerminatedTasks,
  getDeletedTasks,
  getDscRegister,
  getEachClientProfile,
};
