import { Box, Typography } from "@mui/material"
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { StyledTimelineIcon } from "views/ClientProfile/styles";
import moment from "moment";
import { ResType } from "types";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getActivityLogData } from "api/services/automation/income-tax";

import { useState } from "react";
import DateRange from "views/billing/dashboard/DateRange";

const ClientIncomeTaxActivityLog = () => {
    const params = useParams();
    const [dates, setDates] = useState({ fromDate: null, toDate: null });
    console.log("params.incometaxid", params.incometaxid)
    const { data, isLoading }: ResType = useQuery(
        ["get-income-tax-activity", params.incometaxid, { ...dates }],
        getActivityLogData
    );

    const moduleObject = {
        P: 'Basic Profile',
        F: 'Forms',
        R: 'Returns',
        RWA: "Returns With Attachments",
        OD: 'Outstanding Demand',
        EP: 'e-Proceedings',
    };

    const modulesString = (moduleArray: any) => {
        const matchedValues = moduleArray?.map((item: string) => moduleObject[item] || 'Not Found');
        const remarks = `${matchedValues?.join(', ')}`;
        return remarks
    }

    const completedModules = (moduleArray: any) => {
        const filteredModules = moduleArray?.filter(module => module?.includes("Completed") && module !== "Log In Completed");
        const cleanedModules = filteredModules?.map(module => module?.replace(" Completed", ""));

        const remarks = `${cleanedModules?.join(', ')}`;
        return remarks
    };

    const notCompletedModules = (moduleArray) => {
        const filteredModules = moduleArray?.filter(module => module?.startsWith("No"));

        const cleanedModules = filteredModules?.map(module => module?.replace("No ", ""));
        const remarks = `${cleanedModules?.join(', ')}`;
        return remarks
    };

    const errorCompletedModules = (moduleArray) => {
        const filteredModules = moduleArray?.filter(module => module?.startsWith("Error"));

        const cleanedModules = filteredModules?.map(module => module?.replace("Error in", ""));
        const remarks = `${cleanedModules?.join(', ')}`;
        return remarks
    };

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" mr={3}>
                <DateRange dates={dates} setDates={setDates} />
            </Box>
            <Box height="70vh" overflow="auto">

                <Timeline>
                    {data?.data?.map((item: any, index: number) => (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent
                                sx={{
                                    flex: "none",
                                    paddingRight: "40px",
                                }}
                                color="text.secondary"
                            >
                                <StyledTimelineIcon>
                                    <Typography variant="subtitle2" color="primary">
                                        {moment(item?.createdAt).format("MMMM DD, YYYY")}
                                    </Typography>
                                    <Typography variant="caption" color="rgba(0,0,0,0.4)">
                                        {moment(item?.createdAt).format("hh:mm A")}
                                    </Typography>
                                </StyledTimelineIcon>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{ background: "#F9FAFC", border: "3px solid #0D47A11A" }} />
                                <TimelineConnector sx={{ background: "#0D47A11A" }} />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Box
                                    sx={{
                                        border: "1px solid #E1E9F8",
                                        borderRadius: "10px",
                                        p: 2,
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="subtitle2" color="primary">
                                        {item?.status === "PENDING" ? "PENDING" : item?.remarks}
                                    </Typography>
                                    <Typography variant="body2" color="rgba(0,0,0,0.6)" mt={0.5}>
                                        {item?.user?.fullName} synchronizes client information across {modulesString(item?.modules)} modules.
                                    </Typography>
                                    <Typography>{item?.remarks !== "Success" && item?.remarks !== null && item?.status === "COMPLETED" ? item?.remarks : ""}</Typography>
                                    <Typography variant="body2" color="rgba(0,0,0,0.6)" mt={0.5}>
                                        {item?.status === "COMPLETED" && item?.remarks === "Success" ? `Client data has been successfully updated for ${completedModules(item?.completeModules)}` : ""}
                                    </Typography>
                                    <Typography variant="body2" color="rgba(0,0,0,0.6)" mt={0.5}>
                                        {notCompletedModules(item?.completeModules).length === 0 ? "" : ` ${item?.status === "COMPLETED" && item?.remarks === "Success" ? ` There is no  Client data for ${notCompletedModules(item?.completeModules)}` : ""}  `}
                                    </Typography>
                                    <Typography variant="body2" color="rgba(0,0,0,0.6)" mt={0.5}>
                                        {errorCompletedModules(item?.completeModules).length === 0 ? "" : ` ${item?.status === "COMPLETED" && item?.remarks === "Success" ? ` There is Error from Income Tax Portal ${errorCompletedModules(item?.completeModules)}` : ""}  `}
                                    </Typography>

                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </Box>
        </Box>
    )
}


export default ClientIncomeTaxActivityLog