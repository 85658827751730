import { http } from "api/http";

const getReceipts = ({ queryKey }) => {
  return http.get(`/receipts/client-panel/${queryKey[1].clientId}`, { params: { ...queryKey[1] } });
};

const exportReceipts = (data) => {
  return http.post(`/receipts/export`,data);
};

const getCreditBalance = ({ queryKey }) => {
  return http.get("/receipts/credit-balance", { params: { ...queryKey[1] } });
};

const getReceiptPreview = ({ queryKey }) => {
  const { receiptId, orgId, clientId  } = queryKey[1]
  return http.get(`/receipts/${receiptId}/preview`, { params: { orgId: orgId, clientId: clientId } });
};

const downloadReceipt = ({ id }) => {
  return http.post(`/receipts/${id}/download`);
};

export {
  getReceipts,
  getCreditBalance,
  getReceiptPreview,
  downloadReceipt,
  exportReceipts,
};
