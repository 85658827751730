import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
interface IProps {
    result: any;
}

function AuthorizedSignatury({ result }: IProps) {
    return (
        <Box >
            <Grid container spacing={2} sx={{ p: 2, mt: "0px" }}>
                <Grid item xs={9}>
                </Grid>
                {result?.billingEntity?.signatureStorage && (
                    <Grid item xs={3} sx={{ textAlign: "center" }}>
                        <img
                            style={{
                                width: "200px",
                                height: "100px",
                                objectFit: "contain",
                            }}
                            src={result?.billingEntity?.signatureStorage?.fileUrl}
                            alt=""
                        />
                        <Typography variant="h6" color="#172F51">Authorised Signatory</Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default AuthorizedSignatury;
