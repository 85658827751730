import { http } from 'api/http';

const getGstrRecords = ({ queryKey }: any) => {
    let { limit,
        offset,
        query,
        type,
        selectedYear = '',
        selectedMonth = '',
        rtntype = '',
        selectedQuarter = '',
        category,
        subCategory,
        status,
        jurisdiction,

    } = queryKey[1] || {};
    return http.get("/gstr", {
        params: {
            limit,
            offset,
            type,
            selectedYear,
            selectedMonth,
            rtntype,
            selectedQuarter,
            category,
            subCategory,
            status,
            jurisdiction,
            ...query,
        },
    });
};

const getGstrClientRecords = ({ queryKey }: any) => {
    return http.get('/gstr/compliance', {
        params: {
            ...queryKey[1],
        }
    })
}

const createGstrClient = (data: any) => {
    return http.post("/gstr", data);
};

const createGstrPromiseClient = (data: any) => {
    return http.post("/promise", data);
}

const getSingleClientReturns = (data: any) => {
    return http.post('/promise/client', data)
}

const syncSingleClientReturns = (data: any) => {
    return http.post('/promise/client-sync', data)
}

const clearPending = () => {
    return http.get('/promise/status')
}

const getClients = ({ queryKey }) => {
    return http.get("/gstr/clients", { params: { ...queryKey[1] } });
};

export {
    getGstrRecords,
    createGstrClient,
    createGstrPromiseClient,
    // clearPending,
    getClients,
    getGstrClientRecords,
    getSingleClientReturns,
    syncSingleClientReturns
}



