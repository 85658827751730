import { Box, Card, Grid, Typography } from "@mui/material";
import { getIncomeTaxNewUpdates } from "api/services/automation/income-tax";
import Table from "components/Table";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ResType } from "types";
import UpdatesEproceedingFya from "views/atom-pro/NewUpdates/eProccedingFya";
import UpdatesEproceedingFyi from "views/atom-pro/NewUpdates/eProceedingFyi";
import UpdatesForms from "views/atom-pro/NewUpdates/forms";
import UpdatesOutstandingDemand from "views/atom-pro/NewUpdates/outstandingDemand";

const NewUpdates = () => {
    const navigate = useNavigate()
    const { data, isLoading }: ResType = useQuery(
        ["Income-tax-new-updates"],
        getIncomeTaxNewUpdates
    );
    const handleClick = (row) => {
        navigate(`/atom-pro/income-tax/new-updates/${row?.id}`);
    };
    console.log(data)
    const columns = [
        {
            key: "id",
            title: "id"
        },
        {
            key: "client.displayName",
            title: "Client"
        },
        {
            key: "id",
            title: "Return",
            render: (row) => <Typography>New :{row?.returns?.newItem?.length} Updated : {row?.returns?.updateItem?.length}</Typography>
        },
        {
            key: "id",
            title: "Forms",
            render: (row: any) => <Typography>New : {row?.forms?.newItem?.length}</Typography>
        },
        {
            key: "id",
            title: "Outstanding Demand",
            render: (row: any) => <Typography>New : {row?.outstandingDemand?.newItem?.length}</Typography>
        },
        {
            key: "id",
            title: "e-Proceeding(FYA)",
            render: (row: any) => <Typography>New : {row?.eProceedingFya?.newItem?.length}</Typography>
        },
        {
            key: "id",
            title: "e-Proceeding(FYI)",
            render: (row: any) => <Typography>New : {row?.eProceedingFyi?.newItem?.length}</Typography>
        }
    ]
    return (

        <Box sx={{ padding: 2 }} overflow="auto" height={"600px"}>
            <Table
                sx={{ height: "75vh" }}
                data={data?.data || []}
                columns={columns}
                loading={isLoading}
                onRowClick={handleClick}
            />
            {/* <Typography variant="h6" gutterBottom>Returns Changes</Typography>
            {data?.data?.map((update: any, index: any) => (
                <Box key={index} sx={{ marginBottom: 2, borderBottom: '1px solid #ccc', paddingBottom: 2 }} >
                    <Typography variant="subtitle1" color="#0066cc">Client Name : {update?.client?.displayName}</Typography>
                    {
                        update?.returns?.updateItem && update?.returns?.updateItem?.length > 0 && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color={"primary"}>Updated Returns : {update?.returns?.updateItem?.length}</Typography>
                                </Grid>
                                {update?.returns?.updateItem?.map(item => (
                                    <Grid item xs={4} >
                                        <Card key={item.id} sx={{ padding: 1 }} onClick={() => handleBoxClick(item.id)} >

                                            <Typography variant="body2"><span style={{ fontWeight: "600", color: "#004d99" }}>Acknowledgment Number : </span>{item?.ackNum}</Typography>
                                            {item.changes && (
                                                <Box sx={{ marginTop: 1 }}>
                                                    {item?.changes?.newList?.length > 0 && (
                                                        <Box>
                                                            <Typography variant="body2"><span style={{ fontWeight: "600", color: "#004d99" }}>New Status : </span> {item?.changes?.newList?.[0]?.statusDesc}</Typography>
                                                        </Box>
                                                    )}
                                                    {item?.changes?.oldList?.length > 0 && (
                                                        <Box>
                                                            <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#004d99" }}>Old Status : </span> {item?.changes?.oldList[0]?.statusDesc}</Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            )}
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    }

                    {
                        update?.returns?.newItem && update?.returns?.newItem?.length > 0 && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color={"primary"}>New Returns : {update?.returns?.newItem?.length}</Typography>
                                </Grid>

                                {update?.returns?.newItem?.map(item => (
                                    <Grid item xs={4} key={item.id}>
                                        <Card >
                                            <Grid item sx={{ marginBottom: 1 }} onClick={() => handleBoxClick(item.id)} bgcolor={"#ffffff"} m={0.5} padding={0.5}>
                                                <Typography variant="body2"> <span style={{ fontWeight: "600", color: "#00264d" }}>Acknowledgment Number : </span> {item?.ackNum}</Typography>
                                                <Typography variant="body2" ><span style={{ fontWeight: "600", color: "#00264d" }}>assmentYear : </span> {item?.assmentYear}</Typography>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    }

                    <UpdatesForms forms={update?.forms} />
                    <UpdatesOutstandingDemand demands={update?.outstandingDemand} />
                    <UpdatesEproceedingFya eProceedingFya={update?.eProceedingFya} />
                    <UpdatesEproceedingFyi eProceedingFyi={update?.eProceedingFyi} />
                </Box>
            ))} */}
        </Box>
    );
};

export default NewUpdates;
