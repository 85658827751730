import { Box } from "@mui/material";
import { getClient } from "api/services/clients/clients";
import { snack } from "components/toast";
import { invoicingClientsMenu } from "data/constants";
import useQueryParams from "hooks/useQueryParams";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import { StyledProfileNav, StyledProfileNavItem } from "../../../views/ClientProfile/styles";
import UnBilledTasks from "./unbilledtasks";
import BilledTasks from "./billedtasks";
import Billing from "./billing";
import Billingoverview from "./billingoverview";
import ClientReceipts from "./clientreceipts";

function Billingclientview() {

  const navigate = useNavigate();
  const { queryParams } = useQueryParams();
  const clientId = localStorage.getItem('clientId');
  const active = queryParams.tab;

  const { data: clientprofile, isLoading, error }: ResType = useQuery(
    ["client", clientId],
    getClient, {
    onSuccess: (res: any) => {
      navigate(`/billing/clients?tab=overview`);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    }
  });

  return (
    <>
      <Box sx={{ position: "sticky", top: 65, bgcolor: "white", zIndex: 2 }}>
        <StyledProfileNav>
          {invoicingClientsMenu.map((item, index) => (
            <StyledProfileNavItem
              key={index}
              onClick={() => navigate(`?tab=${item.path}`)}
              active={active === item.path ? 1 : 0}
            >
              {item.title}
            </StyledProfileNavItem>
          ))}
        </StyledProfileNav>
      </Box>
      <Box px={2} pl={1} pr={1} pt={3}>
        {active === "overview" && <Billingoverview />}
        {active === "unbilledtasks" && <UnBilledTasks />}
        {active === "billedtasks" && <BilledTasks />}
        {active === "billing" && <Billing />}
        {active === "clientreceipts" && <ClientReceipts />}
      </Box>
    </>


  );
}

export default Billingclientview;

