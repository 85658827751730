import { Box, Typography } from "@mui/material";
import { getReceipts } from "api/services/billing/receipts";
import Table from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { formattedDate } from "utils/formattedDate";
import ReceiptsHeader from "views/billing/receipts/ReceiptsHeader";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { snack } from "components/toast";
import { getReceiptColor } from "views/billing/estimates/getStatusColor";
import { formattedAmount } from "utils/formattedAmount";
import { capitalizeWords } from "utils";
import { paymentMethods } from "data/constants";

const Receipts = () => {
  useTitle("Receipts");

  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selected, setSelected] = useState<any[]>([]);
  const dispatch = useDispatch();
  const clientId = localStorage.getItem('clientId')

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const { data, isLoading }: ResType = useQuery(
    ["receipts", { offset: page * pageCount, limit: pageCount, search, clientId: clientId }],
    getReceipts, {
    onError: (err) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching receipts data`);
      }
    }
  }
  );

  const totalCount = data?.data?.total || 0;

  const handleRowClick = (v: any) => {
    window.open(`/billing/receipts/${v?.id}/preview`);
  };

  const setSearchChange = (e) => {
    setSearch(e);
    setPage(0);
  };

  return (
    <Box p={3} pl={1} pr={1} pb={0}>
        <ReceiptsHeader
          clearSelection={() => setSelected([])}
          selected={selected}
          search={search}
          setSearch={setSearch}
        />
        <Table
          sx={{ height: 'calc(75vh - 10px)', mb: 0, pb: 0 }}
          selection={{ selected, setSelected }}
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data?.result || []}
          columns={columns}
          loading={isLoading}
          onRowClick={handleRowClick}
        />
    </Box>
  );
};

let columns = [
  {
    key: "billingEntity.tradeName",
    title: "Billing Entity",
  },
  {
    key: "receiptDate",
    title: "Receipt Date",
    render: (row: any) => formattedDate(row?.receiptDate),
  },
  {
    key: "receiptNumber",
    title: "Receipt #",
  },

  {
    key: "type",
    title: "Receipt Type",
    render: (row: any) => capitalizeWords(row.type == 'INVOICE' ? "Payment Receipt" : "Advance"),
  },
  {
    key: "client.displayName",
    title: "Client",
  },

  {
    key: "paymentMode",
    title: "Receipt Mode",
    render: (row: any) => paymentMethods[row?.paymentMode]
  },

  {
    key: "amount",
    title: "Amount (₹)",
    render: (row: any) => formattedAmount((row?.amount * 1 || 0) + (row?.creditsUsed * 1 || 0)),
  },
  {
    key: "status",
    title: "Status",
    render: (row: any) => (
      <Box
        sx={{
          background: getReceiptColor(row?.status),
          px: 2,
          py: "4px",
          color: "white",
          borderRadius: "10px",
          textAlign: "center",
          display: "inline-flex",
        }}
      >
        <Typography variant="body2">
          {capitalizeWords(row?.status)}
        </Typography>
      </Box>
    ),
  }
];

export default Receipts;
