import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { StyledParticularsTable } from "views/billing/styles";
import moment from "moment";
import { formatToRupees } from "utils/formattedAmount";

function InvoiceDetails({ result }) {

    const totalPgAmount = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + item.tPgAmount * 1
        }, 0);
    };

    const totalPgSettled = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + ((item?.pureAgentAmount * 1))
        }, 0);
    };

    const totalServiceAmount = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + ((item?.invoice?.grandTotal * 1) - (item?.invoice?.totalCharges * 1))
        }, 0);
    };

    const totalServiceSettled = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + ((item?.serviceAmount * 1))
        }, 0);
    };

    const totalPgDue = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + ((item?.overallPgDue * 1))
        }, 0);
    };

    const totalServiceDue = () => {
        return result?.receiptParticular?.reduce((accumulator, item: any) => {
            return accumulator + ((item?.overallServiceDue * 1))
        }, 0);
    };

    const invoiceStatus = (status: string) => {
        switch (status) {
            case 'PARTIALLY_PAID':
                return <span style={{ backgroundColor: "#FF9F72", color: "white", borderRadius: "7px", paddingLeft: "5px", paddingRight: "5px" }}>Partially Paid</span>
            case 'PAID':
                return <span style={{ backgroundColor: "#5AB77F", color: "white", borderRadius: "7px", paddingLeft: "5px", paddingRight: "5px" }}>Paid</span>
            case 'APPROVAL_PENDING':
                return <span style={{ backgroundColor: "#00AFC2", color: "white", borderRadius: "7px", paddingLeft: "5px", paddingRight: "5px" }}>Created</span>
        }
    }

    return (
        <Box mt={3}>
            {/* <Box mb={2}>
                <Typography variant="subtitle1">Invoices</Typography>
            </Box> */}
            {/* <TableContainer>
                <StyledParticularsTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Invoices No
                            </TableCell>
                            <TableCell>
                                Invoice Date
                            </TableCell>
                            <TableCell>
                                Pure Agent Amount
                            </TableCell>
                            <TableCell>
                                Pure Agent Amount Settled
                            </TableCell>
                            <TableCell>
                                Pure Agent Amount Due
                            </TableCell>

                            <TableCell>
                                Service Amount
                            </TableCell>
                            <TableCell>
                                Service Amount Settled
                            </TableCell>
                            <TableCell>
                                Service Amount Due
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result?.receiptParticular?.map((item: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box >
                                        <Typography fontSize="10px" > {invoiceStatus(item?.invoice?.status)}</Typography>
                                        <Typography>{item?.invoice?.invoiceNumber}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell> {moment(result?.invoice?.invoiceDate).format('DD-MM-YYYY').toString()}</TableCell>
                                <TableCell>{formatToRupees(item?.tPgAmount)}</TableCell>
                                <TableCell>{formatToRupees((item?.pureAgentAmount * 1))}</TableCell>
                                <TableCell>{formatToRupees(item?.overallPgDue * 1)}</TableCell>
                                <TableCell>{formatToRupees((item?.invoice?.grandTotal * 1) - (item?.invoice?.totalCharges * 1))}</TableCell>
                                <TableCell>{formatToRupees(item?.serviceAmount * 1)}</TableCell>
                                <TableCell>{formatToRupees((item?.overallServiceDue))}</TableCell>
                            </TableRow>))}
                        <TableRow key={'final'}>
                            <TableCell>Total</TableCell>
                            <TableCell> -</TableCell>
                            <TableCell>{formatToRupees(totalPgAmount())}</TableCell>
                            <TableCell>{formatToRupees(totalPgSettled())}</TableCell>
                            <TableCell>{formatToRupees(totalPgDue())}</TableCell>

                            <TableCell>{formatToRupees(totalServiceAmount())}</TableCell>
                            <TableCell>{formatToRupees(totalServiceSettled())}</TableCell>
                            <TableCell>{formatToRupees(totalServiceDue())}</TableCell>
                        </TableRow>

                    </TableBody>

                </StyledParticularsTable>

            </TableContainer> */}
            <TableContainer>
                <StyledParticularsTable>
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={2} sx={{ border: "1px solid white" }} >Invoice No</TableCell>
                            <TableCell rowSpan={2} style={{ border: '1px solid white' }}>Invoice Date</TableCell>
                            <TableCell colSpan={3} style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid white' }}>Pure Agent</TableCell>
                            <TableCell colSpan={3} style={{ verticalAlign: 'middle', textAlign: 'center', border: '1px solid white' }}>Service Amount</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: '1px solid white' }}>Invoiced</TableCell>
                            <TableCell style={{ border: '1px solid white' }}>Settled</TableCell>
                            <TableCell style={{ border: '1px solid white' }}>Due</TableCell>

                            <TableCell style={{ border: '1px solid white' }}>Invoiced</TableCell>
                            <TableCell style={{ border: '1px solid white' }}>Settled</TableCell>
                            <TableCell style={{ border: '1px solid white' }}>Due</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result?.receiptParticular?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box>
                                        <Typography fontSize="10px">{invoiceStatus(item?.invoice?.status)}</Typography>
                                        <Typography>{item?.invoice?.invoiceNumber}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>{moment(result?.invoice?.invoiceDate).format('DD-MM-YYYY').toString()}</TableCell>
                                <TableCell>{formatToRupees(item?.tPgAmount * 1)}</TableCell>
                                <TableCell>{formatToRupees(item?.pureAgentAmount * 1)}</TableCell>
                                <TableCell>{formatToRupees(item?.overallPgDue * 1)}</TableCell>
                                <TableCell>{formatToRupees((item?.invoice?.grandTotal * 1) - (item?.invoice?.totalCharges * 1))}</TableCell>
                                <TableCell>{formatToRupees(item?.serviceAmount * 1)}</TableCell>
                                <TableCell>{formatToRupees(item?.overallServiceDue * 1)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow key={'final'}>
                            <TableCell>Total</TableCell>
                            <TableCell> - </TableCell>
                            <TableCell>{formatToRupees(totalPgAmount())}</TableCell>
                            <TableCell>{formatToRupees(totalPgSettled())}</TableCell>
                            <TableCell>{formatToRupees(totalPgDue())}</TableCell>
                            <TableCell>{formatToRupees(totalServiceAmount())}</TableCell>
                            <TableCell>{formatToRupees(totalServiceSettled())}</TableCell>
                            <TableCell>{formatToRupees(totalServiceDue())}</TableCell>
                        </TableRow>
                    </TableBody>
                </StyledParticularsTable>
            </TableContainer>


        </Box>


    );
}

export default InvoiceDetails;