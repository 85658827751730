import { Box, Typography } from "@mui/material"
import { getClientForm, getClientReturn } from "api/services/automation/income-tax";
import Table from "components/Table";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";


const ClientIncometaxReturns = () => {

    const params = useParams();
    const navigate = useNavigate()
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { data, isLoading, error }: ResType = useQuery(
        ["get-client-return", params.incometaxid, {  limit: pageCount, offset: page * pageCount }],
        getClientReturn,
        {
          onSuccess: (res) => {
            setTotalCount(res?.data?.count);
          },
        }
      );

    


    const getFilingType = (type) => {
        const filingTypes = {
            "O": "Original Return",
            "D": "Defective Return",
            "R": "Revised Return",
            "T": "Rectification Return",
            "U": "Updated Return"
        };
        const abc = filingTypes[type]
        return abc
    }

    const getformType = (type) => {
        const itrTypes = {
            "1": "ITR-1",
            "2": "ITR-2",
            "2A": "ITR-2A",
            "3": "ITR-3",
            "4": "ITR-4",
            "4S": "ITR-4S",
            "5": "ITR-5",
            "6": "ITR-6",
            "7": "ITR-7"
        };

        const abc = itrTypes[type]
        return abc
    }

    const verificationStatus = {
        Y: "Verified",
        N: "Not Verified", //red
        'empty': "--"
      };

      const generateAssessmentYear = (assessmentYear) => {
        if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
          return "NA";
        } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
          const year = parseInt(assessmentYear);
          const nextYear = (year + 1).toString().slice(-2);
          return `AY ${year}-${nextYear}`;
        } else {
          return assessmentYear;
        }
      };
    let columns = [
       
        {
            key: "assmentYear",
            title: "Assessment Year",
            render: (v) => (
              <Typography
              >
                {generateAssessmentYear(v?.assmentYear)}
              </Typography>
            ),
          },
        {
            key: "filingTypeCd",
            title: "Filing Type",
            render: (row: any) => {
                return (
                    <Typography>{getFilingType(row?.filingTypeCd)}</Typography>
                )
            },

        },
        {
            key: "ackNum",
            title: "Acknowledgement #",
        },

        {
            key: "formtypeCd",
            title: "ITR",
            render: (row: any) => {
                return (
                    <Typography>{getformType(row?.formtypeCd)}</Typography>
                )
            },
        },
        {
            key: "verStatus",
            title: "Verification Status",
            render: (row: any) => (
              <Typography
                sx={{
                  backgroundColor: row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : ""),
                  display: "inline-block",
                  padding: "1px 5px",
                  border: row?.verStatus ? `1px solid ${row?.verStatus === 'Y' ? "#79c97a" : (row?.verStatus === 'N' ? "#ff3535" : "")}` : "",
                  borderRadius: "4px",
                  color: "#222222",
                  fontSize: "12px",
                }}
              >
                {row?.verStatus  ? verificationStatus[row?.verStatus] : '--'}
              </Typography>
            ),
          },

    ];

    

    const handleRowClick = (v: any) => {
        navigate(`/atom-pro/income-tax/incometax/${params.incometaxid}/return/${v?.id}`)
    }

    return (
        <Box>
            {data && data?.data?.count > 0 ?
                <>
                    <Table
                        // selection={{ selected, setSelected }}
                        pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                        data={data?.data?.result|| []}
                        columns={columns}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                    />
                </> : <>
                    <Box textAlign="center" mt={20}>
                        <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                            No Data present
                        </Typography>
                    </Box>
                </>}

        </Box>
    )
}

export default ClientIncometaxReturns