import { Box, Button, } from "@mui/material";
import Table from "components/Table";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { getGstrClientRecords, syncSingleClientReturns } from "api/services/clients/gstr-clients";
import { snack } from "components/toast";
import React from "react";
import { RegularTaxHeadings } from "data/constants";
import { getGstFinancialYears } from "utils/FinancialYears";
import { gstReturncolumns } from "views/client-view/Compliance/deductor";
import EmptyPage from "components/EmptyPage";
import Loader from "components/Loader";
import AddInvoice from "views/client-view/Compliance/SyncClient";
import FinancialYearAndMonthSelector from "../../../YearPeriodSelect";
import { useGstrClientData } from "context/GstrClientData ";


function Gstr3b() {
    const financialYears = getGstFinancialYears()
    const [selectedYear, setSelectedYear] = React.useState(financialYears[0]);
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();
    const [tableData, setTableData] = useState<any[]>([]);
    const [lodd, setLoad] = useState(false);
    const { data } = useGstrClientData();

    const { data: complianceData, isLoading }: ResType = useQuery(
        [
            "compliance",
            {
                clientId: data?.data?.id,
                type: 'regular_taxpayer',
                rtntype: 'GSTR3B',
                selectedYear,

            },
        ],
        getGstrClientRecords, {
        enabled: !!selectedYear,

        onSuccess: (res: any) => {
            setTableData(res?.data)
        },
        onError: (err: any) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching register data`);
            }
        }
    });

    const { mutate: syncMutate, isLoading: load } = useMutation(syncSingleClientReturns, {
        onSuccess: (res) => {
            setLoad(false);
            setTimeout(() => {
                queryClient.invalidateQueries("gstr2x-compliance");
            }, 200)
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        }

    })

    const syncData = async () => {
        if (!selectedYear) return snack.error('Select Financial Year');
        const filteredRetPrdValues = tableData.filter(item => item['retPrd'] !== null && item['retPrd'].length === 6).map(item => item['retPrd']);
        if (filteredRetPrdValues.length === 12) return snack.success("One-Time Sync! Your GST Returns data has been synced and is ready to use. Since there's no option to revise past GST returns, your data will stay synced.");
        setLoad(true);
        syncMutate({
            financialYear: selectedYear,
            type: 'regular_taxpayer',
            clientId: data?.data?.id,
            gstNumber: data?.data?.gstNumber,
            gstrRegisterId: tableData[0]?.gstrRegisterId,
            filledMonths: filteredRetPrdValues
        })
    };
    const onClickSync = () => {
        setOpen(true);
    };

    if (isLoading || load) return <Loader />

    return (complianceData?.data == "Not-synchronized" ? (<>

        <EmptyPage
            minHeight="50vh"
            title={RegularTaxHeadings.GST_NOT_VERIFIED}
            {...(true && {
                btn2Title: "Sync To Gst Returns",
                btn2Action: () => onClickSync(),
            })}
        />
        <AddInvoice open={open} setOpen={setOpen} data={{
            financialYear: selectedYear,
            type: 'regular_taxpayer',
            clientId: data?.data?.id,
            rtntype: 'GSTR3B    ',
            gstNumber: data?.data?.gstNumber
        }} />
    </>) : (

        <Box p={3} >
            <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <FinancialYearAndMonthSelector
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                    />&emsp;
                </Box>
            </Box>
            <Table
                sx={{ mt: 3 }}
                loading={isLoading || lodd}
                data={tableData || []}
                columns={gstReturncolumns}
                heading={RegularTaxHeadings.GSTR3B}

            />
        </Box >
    )
    );
}



export default Gstr3b;
