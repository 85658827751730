import { Box, Button, Grid, IconButton, List, ListItem, Typography } from "@mui/material"
import { useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { useParams } from "react-router-dom";
import { getClientAutoStatus } from "api/services/automation/income-tax";
import CredentialsEdit from "./CreadentialEdit";
import { useState } from "react";
import { Edit } from "@mui/icons-material";

const ClientAutoStatus = () => {
    const params = useParams();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false)
    const [state, setState] = useState<any>({})
    const { data, isLoading }: ResType = useQuery(
        ["Client-Auto-Status", params.incometaxid],
        getClientAutoStatus, {
        onSuccess: (res) => {
            setState(res?.data?.autoCredentials)

        },
    }
    );

    let timeoutId = setTimeout(() => {
        queryClient.invalidateQueries("Client-Auto-Status");
    }, 5000);

    // Stop the timeout if condition becomes true
    if (data?.data?.status === "COMPLETED") {
        clearTimeout(timeoutId);
    }

    return (
        <Box m={2} height="75vh" overflow="auto">
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h5">Client Income Tax Status</Typography>

                </Box>
                <Box mr={3}>
                    {data?.data?.remarks === "Invalid Password, Please retry." && (
                        <Box>  <IconButton onClick={(e) => setOpen(true)}>
                            <Edit />
                        </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>

            <Typography mt={1} variant="h6"> Status : {data?.data?.status}</Typography>
            <Typography mt={1} variant="h6"> Remarks : {data?.data?.remarks}</Typography>
            <Box display={"flex"} justifyContent="center" alignItems="center" mt={5}>
                <Box width="100%">
                    {data?.data && (
                        <Grid container>

                            {data?.data?.completeModules?.map(item => (
                                <Grid item xs={3.8} mt={1} m={1} bgcolor={(item?.startsWith("No")) ? "#ffe6f2" : item?.startsWith("Error in") ? "#ff8080" : "#e6ffff"} p={1.5} spacing={2} borderRadius="5px" >
                                    <Typography key={item} textAlign="center">{item}</Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </Box>
            <Box>
                {(data?.data?.remarks === "OTP Base LogIn" || data?.data?.remarks === "Login attempt was unsuccessful due to Multi-Factor Authentication") && (
                    <Box sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: 2 }}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Important Information:
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The automated data extraction process within ATOM Pro's Income Tax PAN Module functions best when client logins don't utilize Multi-Factor Authentication (MFA) methods;
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            When adding clients to ATOM Pro (Income Tax PAN Module), please ensure the client login does not use any of the following Multi-Factor Authentication (MFA) methods like:
                        </Typography>
                        <List>
                            <ListItem >
                                1. e-Filing Vault Higher Security (Net Banking | DSC | Aadhaar OTP | Bank Account EVC | Demat Account EVC)
                            </ListItem>
                            <ListItem>
                                2. Static Password
                            </ListItem>
                            <ListItem>
                                3. Aadhaar OTP Login (Single Factor Authentication)
                            </ListItem>
                        </List>
                        <Typography variant="body1" gutterBottom>
                            <span style={{ color: "red", fontWeight: "600" }} > Reason:</span>   Automation processes running in the background rely on simpler login methods and may not be able to handle these complex MFA methods. Using these methods can lead to disruptions or errors during data extraction. Sometimes these external factors affect the timely delivery of OTP/EVC and delayed or expired codes during automation can cause disruptions and erroneous data extraction.
                        </Typography>
                        <Typography variant="body1">
                            For a smooth automation process, please ensure you use a login method without MFA.
                        </Typography>
                    </Box>
                )

                }
            </Box>

            <CredentialsEdit open={open} setOpen={setOpen} state={state} setState={setState} />
        </Box>
    )
}

export default ClientAutoStatus