import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import SearchContainer from "components/SearchContainer";
import { useState } from "react";
import Actions from "./Actions";

interface IProps {
  selected: any[];
  search: string;
  setSearch: (v: string) => void;
  clearSelection: () => void;
}

function InvoicesHeader(props: IProps) {
  const { selected, search, setSearch, clearSelection } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Box
        mb={2}
        pl={2}
        pr={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SearchContainer debounced value={search} placeHolder="Search by Invoice # | Client Name" onChange={setSearch} />
        <Box display="flex" gap={1}>
            {/* <Button
              onClick={handleExport}
              endIcon={<BrowserUpdatedOutlinedIcon fontSize="small" />}
              color="primary"
              variant="outlined"
              disabled={isLoading}
            >
              Export
            </Button> */}

          <Button
            endIcon={<ArrowDropDownOutlinedIcon />}
            color="primary"
            variant="outlined"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            Actions
          </Button>
        </Box>
      </Box>
      <Actions
        clearSelection={clearSelection}
        selected={selected}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
}

export default InvoicesHeader;
