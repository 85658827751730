export const getGstFinancialYears = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    // Subtract one month from the current date
    today.setMonth(today.getMonth() - 1);
    const lastMonthYear = today.getFullYear();
    const lastMonth = today.getMonth(); // Adjusted month after subtraction

    // Determine the starting financial year
    // If last month is March (2) or before, the financial year started last calendar year
    const startFinancialYear = lastMonth < 3 ? lastMonthYear - 1 : lastMonthYear;

    const financialYears: string[] = [];
    for (let year = startFinancialYear; year >= 2017; year--) {
        financialYears.push(`${year}-${(year + 1).toString().slice(2)}`);
    }

    return financialYears;
};

export const incrementFinancialYear = (year) => {
    const [start, end] = year.split('-').map(Number);
    return `${start + 1}-${end + 1}`;
}

