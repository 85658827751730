export enum  Permissions {
  VIEW_ALL_TASKS = "task_view_all",
  TASK_IPRO_VIEW = "task_ipro_view",
  TASK_CKECKLIST_VIEW = "task_checklist_view",
  TASK_MILESTONE_VIEW = "task_milestone_view",
  TASK_SUBTASK_VIEW = "task_subtask_view",
  TASK_ATTACHMENTS_VIEW = "task_attachments_view",
  TASK_LOG_HOURS_VIEW = "task_log_hours_view",
  TASK_APPROVALS_VIEW = "task_approvals_view",
  TASK_EVENTS_VIEW = "task_events_view",
  TASK_QUANTUM_VIEW = "task_quantum_view",
  TASK_TIMELINE_VIEW = "task_timeline_view",
  TASK_EXPENDITURE_VIEW = "task_expenditure_view",
  CLIENT_PROFILE_VIEW = "client_profile_view",
  CLIENT_PASSWORDS_VIEW = "client_passwords_view",
  CLIENT_KYB_VIEW = "client_kyb_view",
  CLIENT_REGISTER_VIEW = "client_register_view",
  CLIENT_RECURRING_PROFILE_VIEW = "client_recurring_profile_view",
  CLIENT_RESET_PASSWORD = "client_reset_password",
  CLIENT_KYB_CREATE = "client_kyb_create",
  CLIENT_KYB_EDIT = "client_kyb_edit",
  CLIENT_KYB_DELETE = "client_kyb_delete",
  DASHBOARD_VIEW = "dashboard_view",
  STORAGE_VIEW = "storage_view",
  BILLING_CLIENT_OVERVIEW = "client_overview",
  BILLING_CLIENT_INVOICE = "client_invoice",
  BILLING_CLIENT_RECEIPT = "client_receipt",
  ATOM_PRO_INCOMETAX = "atom_pro_incometax",
  ATOM_PRO_GST = "atom_pro_gst",
  TASK_UDIN_VIEW="task_udin_view",
}
