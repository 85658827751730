import loadable from "@loadable/component";
import Tasks from "pages/Tasks";
import Billing from "pages/billing";
import Billingclientoverview from "pages/billing/clients/billingclientoverview";
import Billingclientview from "pages/billing/clients/billingclientview";
import Invoices from "pages/billing/invoices";
import InvoicePreview from "pages/billing/invoices/invoice-preview";
import Receipts from "pages/billing/receipts";
import ReceiptPreview from "pages/billing/receipts/receipt-preview";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import IncomeTaxReturns from "pages/atom-pro/income-tax-returns";
import IncomeTaxForms from "pages/atom-pro/income-tax-forms";
import IncomeTaxEProceedings from "pages/atom-pro/income-tax-eproceedings";
import IncomeTaxOrder from "pages/atom-pro/income-tax-order";
import FormFullView from "views/atom-pro/formFullView";
import ClientFullView from "views/atom-pro/clientFullView";
import ClientIncomeTaxView from "views/atom-pro/ClientIncomeTaxView";
import ClientForm from "views/atom-pro/clientForm";
import ReturnFullView from "views/atom-pro/ReturnFullView";
import ClientIncometaxReturns from "views/atom-pro/clientReturns";
import OutstandingDemandView from "views/atom-pro/OutstandingDemandView";
import ClientDemand from "views/atom-pro/clientDemand";
import EproceedingView from "views/atom-pro/EproceedingView";
import FYANoticeView from "views/atom-pro/FYANoticeView";
import FYINoticeView from "views/atom-pro/FYINoticeView";
import EproceedingFyiView from "views/atom-pro/EproceedingFyiView";
import ClientProceeding from "views/atom-pro/clientProceeding";
import ClientIncomeTaxActivityLog from "views/atom-pro/clientActivityLog";
import ClientAutoStatus from "views/atom-pro/clientAutoStatus";
import IncomeTaxReports from "pages/atom-pro/reports";
import NewUpdates from "pages/atom-pro/new-updates";
import UpdatesFullVIew from "views/atom-pro/NewUpdates/ItemFullView"
import GstrCompliance from "views/atom-pro/Gstr/modules/gstrCompliance";
import PageWithPermission from "components/PageWithPermission";
import { Permissions } from "data/permissons";

const Login = loadable(() => import("pages/login"));
const Layout = loadable(() => import("layout/primarylayout"));
const FillForm = loadable(() => import("pages/fill-form"));
const ClientProfile = loadable(() => import("pages/client-profile"));
const Profile = loadable(() => import("views/ClientProfile/profile"));
const Credentials = loadable(() => import("views/ClientProfile/credentials"));
const Kyb = loadable(() => import("views/ClientProfile/kyb"));
const KybDetails = loadable(() => import("pages/view-kyb"));
const KybEntry = loadable(() => import("views/ClientProfile/kyb/KybEntry"));
const KybAuditLog = loadable(() => import("pages/view-kyb-audit-log"));
const MyProfile = loadable(() => import("pages/my-profile"));
const ResetPassword = loadable(() => import("pages/reset-password"));
const TaskDetails = loadable(() => import("pages/task-view"));
const RecurringProfile = loadable(() => import("views/ClientProfile/recurringProfile"));
const ViewDscRegister = loadable(() => import("views/ClientProfile/dscRegister/view-dsc-register"));
const DscRegister = loadable(() => import("views/ClientProfile/dscRegister"));
const ClientTasks = loadable(() => import("views/ClientProfile/clientTasks"));
const Archives = loadable(() => import("views/ClientProfile/Archives"));
const Attachments = loadable(() => import("pages/attachments"));
const Dashboard = loadable(() => import("pages/dashboard"));
const GstrClients = loadable(() => import("pages/atom-pro/Gst/clients"))
const GstrView = loadable(() => import("views/atom-pro/Gstr/modules/gstrView"))
const GstrProfile = loadable(() => import("views/atom-pro/Gstr/modules/profile"))
const AdditionalNotice = loadable(() => import("views/atom-pro/Gstr/modules/gstrAdditionalNotice"))
const NoticeAndOrders = loadable(() => import("views/atom-pro/Gstr/modules/noticeAndOrders"))
const NoticeAndOrderFullView = loadable(() => import("views/atom-pro/Gstr/Fullview/noticeAndOrderFullView"))
const AdditonalNoticeFullView = loadable(() => import("views/atom-pro/Gstr/Fullview/additonalNoticeFullView"))
const AtomPro = loadable(() => import("pages/atom-pro"));
const GstPro = loadable(() => import("pages/atom-pro/Gst"));
const GStProDashboard = loadable(() => import("pages/atom-pro/Gst/dashboard"));
const Websites = loadable(() => import("pages/atom-pro/website"));
const IncomeTaxDashboard = loadable(() => import("pages/atom-pro/income-tax-dashboard"));
const OutstandingDemand = loadable(() => import("pages/atom-pro/outstanding-demand"));
const ClientsIncomeTax = loadable(() => import("pages/atom-pro/clients"));
const FyaNotice = loadable(() => import("views/atom-pro/eProceedingNotice/fyaNotice"))
const EproceedingNotice = loadable(() => import("views/atom-pro/eProceedingNotice/eproceedingNotice"));
const Invalidate = loadable(() => import("views/atom-pro/invalidate"));
const GstRegular = loadable(() => import("views/register/GstReturns/Regular"));
const GstCollector = loadable(() => import("views/register/GstReturns/Collector"));
const GstDeductor = loadable(() => import("views/register/GstReturns/Deductor"));
const AtomProCalendar = loadable(() => import("views/atom-pro/Dashboard/Calendar"));

function RoutesContainer() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard">
            <Route index element={
              <PageWithPermission name={Permissions.DASHBOARD_VIEW}>
                <Dashboard />
              </PageWithPermission>
            } />
          </Route>
          <Route path="client-profile" element={<ClientProfile />}>
            <Route index element={
              <PageWithPermission name={Permissions.CLIENT_PROFILE_VIEW}>
                <Profile />
              </PageWithPermission>
            } />
            <Route path="credentials" element={
              <PageWithPermission name={Permissions.CLIENT_PASSWORDS_VIEW}>
                <Credentials />
              </PageWithPermission>
            } />
            <Route path="kyb" element={
              <PageWithPermission name={Permissions.CLIENT_KYB_VIEW}>
                <Kyb />
              </PageWithPermission>
            } />
            <Route path="dsc-register">
              <Route index element={
                <PageWithPermission name={Permissions.CLIENT_REGISTER_VIEW}>
                  <DscRegister />
                </PageWithPermission>
              } />
              <Route path=":dscId" element={
                <PageWithPermission name={Permissions.CLIENT_REGISTER_VIEW}>
                  <ViewDscRegister />
                </PageWithPermission>
              } />
            </Route>
            <Route path="recurring-profile" element={
              <PageWithPermission name={Permissions.CLIENT_RECURRING_PROFILE_VIEW}>
                <RecurringProfile />
              </PageWithPermission>
            } />
            <Route path="client-tasks" element={
              <PageWithPermission name={Permissions.VIEW_ALL_TASKS}>
                <ClientTasks />
              </PageWithPermission>
            } />
            <Route path="archives" element={
              <PageWithPermission name={Permissions.VIEW_ALL_TASKS}>
                <Archives />
              </PageWithPermission>
            } />
          </Route>
          <Route path="tasks">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.VIEW_ALL_TASKS}>
                  <Tasks />
                </PageWithPermission>
              }
            />
            <Route path=":taskId" element={<TaskDetails />} />
          </Route>
          <Route path="billing" element={<Billing />}>
            <Route path="clients" element={
              <PageWithPermission name={Permissions.BILLING_CLIENT_OVERVIEW}>
                <Billingclientview />
              </PageWithPermission>
            } />
            <Route path="invoices" element={
              <PageWithPermission name={Permissions.BILLING_CLIENT_INVOICE}>
                <Invoices />
              </PageWithPermission>
            } />
            <Route path="receipts" element={
              <PageWithPermission name={Permissions.BILLING_CLIENT_RECEIPT}>
                <Receipts />
              </PageWithPermission>
            } />
          </Route>
          <Route path="atom-pro">
            <Route index element={<Websites />} />
            <Route path="income-tax">
              <Route index element={<IncomeTaxDashboard />} />
              <Route path="demands" element={<OutstandingDemand />} />
              {/* <Route path="clients" element={<ClientsIncomeTax />} /> */}
              <Route path="returns" element={<IncomeTaxReturns />} />
              <Route path="dashboard" element={<IncomeTaxDashboard />} />
              <Route path="clients" element={<ClientsIncomeTax />} />
              <Route path="calendar" element={<AtomProCalendar />} />
              <Route path="return/:id" element={<ReturnFullView />} />
              <Route path="demand/:id" element={<OutstandingDemandView />} />
              <Route path="proceeding-action/:id" element={<EproceedingView />} />
              <Route path="proceeding-information/:id" element={<EproceedingFyiView />} />
              <Route path="proceeding-action/notice-fya/:itemId" element={<FYANoticeView />} />
              <Route path="proceeding-information/notice-fyi/:itemId" element={<FYINoticeView />} />
              <Route path="forms" element={<IncomeTaxForms />} />
              <Route path="proceedings" element={<IncomeTaxEProceedings />} />
              <Route path="order" element={<IncomeTaxOrder />} />
              <Route path="forms/:id" element={<FormFullView />} />
              <Route path="client/:id" element={<ClientFullView />} />
              <Route path="reports" element={<IncomeTaxReports />} />
              <Route path="new-updates" element={<NewUpdates />} />
              <Route path="new-updates/:id" element={<UpdatesFullVIew />} />
              <Route path="fya-notice" element={<FyaNotice />} />
              <Route path="eproceeding-notice" element={<EproceedingNotice />} />
              <Route path="invalidate" element={<Invalidate />} />
              <Route path="incometax/:incometaxid" element={<ClientIncomeTaxView />} >
                <Route index element={<ClientFullView />} />
                <Route path="form" element={<ClientForm />} />
                <Route path="demand" element={<ClientDemand />} />
                <Route path="proceeding" element={<ClientProceeding />} />
                <Route path="form/:id" element={<FormFullView />} />
                <Route path="return" element={<ClientIncometaxReturns />} />
                <Route path="return/:id" element={<ReturnFullView />} />
                <Route path="demand" element={<OutstandingDemand />} />
                <Route path="activity" element={<ClientIncomeTaxActivityLog />} />
                <Route path="client-status" element={<ClientAutoStatus />} />
                {/* <Route path="proceeding" element={<IncomeTaxEProceedings />} /> */}
              </Route>
            </Route>

            <Route path="gst">
              <Route path="dashboard" element={<IncomeTaxDashboard />} />
              <Route path="regular" element={<GstRegular />} />
              <Route path="collector" element={<GstCollector />} />
              <Route path="deductor" element={<GstDeductor />} />
              <Route path="clients" element={<GstrClients />} />
              <Route path="gstr/:gstrid" element={<GstrView />} >
                <Route index element={<GstrProfile />} />
                <Route path="compliance" element={<GstrCompliance />} />
                <Route path="additional-notice" element={<AdditionalNotice />} />
                <Route path="notice-orders" element={<NoticeAndOrders />} />
                <Route path="notice-orders/:id" element={<NoticeAndOrderFullView />} />
                <Route path="additional-notice/:id" element={<AdditonalNoticeFullView />} />
              </Route>
            </Route>
          </Route>
          <Route path="storage">
            <Route
              index
              element={
                <PageWithPermission name={Permissions.STORAGE_VIEW}>
                  <Attachments />
                </PageWithPermission>
              }
            />
          </Route>
          <Route path="my-profile" element={<MyProfile />} />
        </Route>
        <Route path="/billing/invoices/:invoiceId/preview" element={<InvoicePreview />} />
        <Route path="/billing/receipts/:receiptId/preview" element={<ReceiptPreview />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/fill-form/:formId" element={<FillForm />} />
        <Route path="/view-kyb/:formId" element={<KybDetails />}>
          <Route index element={<Navigate to="view-details" />} />
          <Route path="view-details" element={<KybEntry />} />
          <Route path="audit-log" element={<KybAuditLog />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default RoutesContainer;
