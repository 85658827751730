import { DownloadOutlined } from "@mui/icons-material";
import { Divider, Grid, Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { downloadReceipt, getReceiptPreview } from "api/services/billing/receipts";
import Loader from "components/Loader";
import { snack } from "components/toast";
import useQueryParams from "hooks/useQueryParams";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import { handleError } from "utils/handleError";
import AddressDetails from "views/billing/estimates/EstimatePreview/AddressDetails";
import SectionHeading from "views/billing/estimates/SectionHeading";
import PaymentDetails from "views/billing/receipts/ReceiptPreview/PaymentDetails";
import BasicDetails from "views/billing/receipts/ReceiptPreview/BasicDetails";
import OrganizationDetails from "views/billing/receipts/ReceiptPreview/OrganizationDetails";
import InvoiceDetails from "views/billing/receipts/ReceiptPreview/InvoiceDatails";
import PaymentSummary from "views/billing/receipts/ReceiptPreview/PaymentSummary";
import { canceled, vider_logo } from "assets";
import AuthorizedSignatury from "views/billing/receipts/ReceiptPreview/AuthorizedSiganture";
import { Watermark } from "views/billing/styles";
import { ReceiptStatus } from "data/constants";
import AccessDenied from "../invoices/AccressDenied";
import { UserProfileContext } from "context/UserProfile";

export const StyledWatupButton = styled("div")(() => ({
  position: "absolute",
  // width: "40px",
  height: "50px",
  bottom: "30px",
  right: "220px",
  top: "100px",
  zIndex: 100,
  cursor: "pointer"
}));

const ReceiptPreview = () => {
  const params = useParams();
  const { queryParams } = useQueryParams();
  const [isdownloading, setIsdownloading] = useState(false);
  const { data: uData } = useContext(UserProfileContext);
  const clientId = localStorage.getItem('clientId');

  const { data, isLoading }: ResType = useQuery(
    ["receipt-details", { receiptId: params.receiptId, orgId: uData?.organization?.id, clientId: clientId }],
    getReceiptPreview
  );


  const result = data?.data;

  const shareOnWhatsApp = () => {
    const url = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
    const phoneNumber = '91' + data?.data?.billingEntity?.mobileNumber || "9181211 81212";
    const message = encodeURIComponent("Check out this repecipt: " + url);
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=0,height=0,left=-1000,top=-1000`;
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}&attachment=${url}`, "", params);
  }



  if (isLoading) return <Loader />;

  if (result == "Un-Authorized") {
    return (
      <AccessDenied />
    )
  }

  return (
    <Box py={2}>
      <Paper
        sx={{
          maxWidth: 1200,
          margin: 'auto',
          marginBottom: '0px',
          p: 2,
          pb: 0,
          ...(queryParams.fromApi && {
            boxShadow: "none",
          })
        }}
      >
        {result?.status === ReceiptStatus.CANCELLED && (<Watermark imageUrl='https://jss-vider.s3.ap-south-1.amazonaws.com/cancelled.jpg'>
        </Watermark>)}
        <OrganizationDetails result={result} />
        <Divider sx={{ my: 1, borderWidth: 1, borderColor: "#0D46A0" }} />
        <BasicDetails result={result} />
        <PaymentDetails result={result} />
        {result.type == "INVOICE" && <InvoiceDetails result={result} />}
        <PaymentSummary result={result} />
        <AuthorizedSignatury result={result} />
        {/* {result?.termsAndConditions.length > 0 && <Box mt={2}>
          <SectionHeading title="Terms & Conditions" />
          <Box p={1}>
            {result?.termsAndConditions?.map((item: any, index: number) => (
              <Typography mt={1} key={index} variant="body2">
                {index + 1}. {item}
              </Typography>
            ))}
          </Box>
        </Box>} */}
        <Box marginTop='auto' display='flex' justifyContent='center' mt={3}>
          <Typography component='span' variant="h6">Powered by</Typography>
          <img style={{ width: 65, margin: 0, marginLeft: 2 }} src={vider_logo} alt="" />
        </Box>
        <Box textAlign='center' pb={1} color='#908A8A'>
          <Typography variant="body2">This is an electronically generated document.</Typography>
        </Box>
      </Paper>

    </Box>
  );
};

export default ReceiptPreview;
