import { TAX_TYPE } from "data/taxes";
import { IState } from "redux/reducers/createEstimateSlice/types";
import { formatToRupees } from "utils/formattedAmount";

export function getAmount(particular: any) {
  const { discount, discountType, rate, units } = particular;

  if (!rate) return 0;

  let result = units * rate;
  if (discount && discountType === "PERCENT") {
    result = result - (result * discount) / 100;
  }

  if (discount && discountType === "AMOUNT") {
    result = result - discount;
  }

  return result;
}

export function getTotalCharges(otherParticulars: any[]) {
  return otherParticulars.reduce(
    (acc, particular) => acc + +particular.amount,
    0
  );
}

export function getSubTotal(particulars: any[]) {
  return particulars.reduce(
    (acc, particular) => acc + getAmount(particular),
    0
  );
}

export function getGstAmount(particular: any, value: string) {
  const taxableAmount = getAmount(particular);

  if (!value) return 0;

  let gstPercent = 0;

  switch (value) {
    case TAX_TYPE.NON_GST_SUPPLY:
      gstPercent = 0;
      break;
    case TAX_TYPE.NON_TAXABLE:
      gstPercent = 0;
      break;
    case TAX_TYPE.OUT_OF_SCOPE:
      gstPercent = 0;
      break;
    case TAX_TYPE.GST0:
      gstPercent = 0;
      break;
    case TAX_TYPE.GST0_1:
      gstPercent = 0.1;
      break;
    case TAX_TYPE.GST0_25:
      gstPercent = 0.25;
      break;
    case TAX_TYPE.GST1:
      gstPercent = 1;
      break;
    case TAX_TYPE.GST1_5:
      gstPercent = 1.5;
      break;
    case TAX_TYPE.GST3:
      gstPercent = 3;
      break;
    case TAX_TYPE.GST5:
      gstPercent = 5;
      break;
    case TAX_TYPE.GST6:
      gstPercent = 6;
      break;
    case TAX_TYPE.GST7_5:
      gstPercent = 7.5;
      break;
    case TAX_TYPE.GST12:
      gstPercent = 12;
      break;
    case TAX_TYPE.GST18:
      gstPercent = 18;
      break;
    case TAX_TYPE.GST28:
      gstPercent = 28;
      break;
    default:
      gstPercent = 0;
  }

  return (taxableAmount * gstPercent) / 100;
}

export function getTotalGst(particulars: any[]) {
  return particulars.reduce((acc, particular) => {
    return acc + getGstAmount(particular, particular.gst?.value);
  }, 0);
}

export function getRoundOff(state: IState) {
  let totalAmount = getSubTotal(state.particulars);
  let totalGst = state.locationOfSupply !== state?.placeOfSupply ? getTotalGst(state.particulars) : ((formatToRupees(getTotalGst(state.particulars) / 2)) + formatToRupees(getTotalGst(state.particulars) / 2));
  let totalOtherParticularCharges = getTotalCharges(state.otherParticulars);
  let adjustment = +state.adjustment;
  let result =
    state?.hasGst ? (totalAmount + totalGst + totalOtherParticularCharges + adjustment) : (totalAmount + totalOtherParticularCharges + adjustment);

  let roundOff = Math.round(result) - result;

  return roundOff.toFixed(1);
}

export function getGrandTotal(state: IState) {
  const interState = state.locationOfSupply === state?.placeOfSupply;
  let totalAmount = getSubTotal(state.particulars);
  let totalGst = interState ? (parseFloat((getTotalGst(state.particulars) / 2).toFixed(2)) + parseFloat((getTotalGst(state.particulars) / 2).toFixed(2))) : getTotalGst(state.particulars).toFixed(2);
  let totalOtherParticularCharges = getTotalCharges(state.otherParticulars);
  let adjustment = +state.adjustment;
  let result = state.hasGst ?
    (totalAmount + parseFloat(totalGst) + totalOtherParticularCharges + adjustment) : (totalAmount + totalOtherParticularCharges + adjustment);
  // return (Math.round(+result));

  return +result.toFixed(2);
}
