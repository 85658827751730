import { Box, Button, FormControlLabel, Switch, Typography } from "@mui/material";
import { getSingleClientReturns } from "api/services/clients/gstr-clients";
import DialogWrapper from "components/DialogWrapper";
import { snack } from "components/toast";
import _ from "lodash";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    handleClientChange,
    resetState,
    handleAddTasksToParticular,
    handleExistingOtherParticular,
    handleFieldChange,
} from "redux/reducers/createEstimateSlice";


interface StateProps {
    client: any[];
    gstNumber: string;
    registrationType: any;
    syncAll: boolean;
}

let initialState = {
    client: [],
    gstNumber: "",
    registrationType: null,
    syncAll: false
};


const AddInvoice = ({ open, setOpen, data }) => {
    const [state, setState] = useState<StateProps>(_.cloneDeep(initialState));
    const queryClient = useQueryClient();
    const { mutate, isLoading: loading } = useMutation(getSingleClientReturns, {
        onSuccess: (res) => {
            setTimeout(() => {
                queryClient.invalidateQueries("compliance");
                setOpen(false);
            }, 100)

        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });


    const handleAddInvoice = () => {
        mutate({
            ...data,
            syncAll: state.syncAll
        });

    }

    const handleSyncAll = () => {
        setState((prv) => ({ ...prv, syncAll: !state.syncAll }));
    }

    return (
        <DialogWrapper
            open={open}
            setOpen={setOpen}
            title={'Sync Previous Returns'}
            showDot={true}
            color={data?.name ? "#8E24AA" : "#3F51B5"}
        >
            <Box>
                <FormControlLabel
                    label="For a complete picture of your client's GST return history,
                             would you like to perform an initial 
                            synchronization of their data up to the current financial year?"
                    control={
                        <Switch
                            checked={state.syncAll}
                            onChange={handleSyncAll}
                            name="checkedSwitch"
                            color="primary"
                        />
                    }

                />
                <Box display='flex' justifyContent='flex-end'>
                    <Button color="secondary" variant="contained" sx={{
                    }} onClick={handleAddInvoice}>
                        Submit
                    </Button>
                    <Button color="secondary" variant="outlined" onClick={() => setOpen(false)} sx={{ ml: 1 }}>
                        Back
                    </Button>
                </Box>
            </Box>
        </DialogWrapper>
    );
};

export default AddInvoice;


