import { MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { getTasks } from "api/services/tasks";
import Loader from "components/Loader";
import SearchContainer from "components/SearchContainer";
import { UserProfileContext } from "context/UserProfile";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import TaskTable from "views/Tasks";

function Tasks(state) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const clientId = localStorage.getItem("clientId");

  const { data, isLoading, error }: ResType = useQuery(
    [
      "tasks",
      {
        client: clientId,
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        removeCompleted: false,
      },
    ],
    getTasks, {
    onSuccess: (res) => {
      setTotalCount(res?.data?.[1])
    },
  }
  );

  const setSearchChange = (e) => {
    setSearch(e);
    setPage(0);
  };

  return (
    <Box pl={1} pr={1}>
      <Box pr={2} pl={2} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 0, pt: 0 }}>
        <SearchContainer
          minWidth="400px"
          value={search}
          debounced
          placeHolder="Search by Task ID | Task Name"
          onChange={setSearchChange}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TaskTable
          tab="client-task"
          data={data?.data?.[0]}
          totalCount={totalCount}
          page={page}
          pageCount={pageCount}
          setPageCount={setPageCount}
          setPage={setPage}
        />
      )}
    </Box>
  );
}

export default Tasks;