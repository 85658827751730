export interface ParticularChangeType {
  index: number;
  key: string;
  value: any;
}

export interface OtherParticularChangeType {
  id: number;
  index: number;
  key: string;
  value: any;
  taskExpenseType: string;
}

export interface Address {
  displayName: string;
  legalName: string;
  buildingNumber: string;
  floorNumber: string;
  district: string;
  location: string;
  buildingName: string;
  street: string;
  city: string;
  state: string;
  pincode: string;
  email: string;
  mobileNumber: string;
}

export interface Particular {
  name: string;
  hsn: string;
  units: number;
  rate: number;
  discountType: "PERCENT" | "AMOUNT";
  discount: number;
  amount: number;
  gst: string | null;
  taskId?: number;
}

export interface OtherParticular {
  id: number;
  name: string;
  amount: number;
  taskExpenseType: string;
}

export interface BankDetails {
  accountNumber: string;
  bankName: string;
  branchName: string;
  ifscCode: string;
  upiId: string;
  upiAttachment: string;
}

export interface IState {
  billingEntity: number | null;
  locationOfSupply: string;
  approvalHierarchy: any;
  client: string | null;
  selectedItems: number | null;
  placeOfSupply: string;
  estimateNumberType: "AUTO_GENERATED" | "MANUAL";
  autoGeneratedNumber: string;
  estimateNumber: string;
  estimateDate: string | null;
  estimateDueDate: string | null;
  terms: string | null;
  billingEntityAddress: Address | null;
  shippingAddress: Address | null;
  billingAddress: Address | null;
  particulars: Array<Particular>;
  otherParticulars: Array<OtherParticular>;
  bankDetails: BankDetails | null;
  termsAndConditions: Array<string>;
  adjustment: number;
  interState: boolean;
  whatsappCheck: boolean;
  emailCheck: boolean;
  prifix: string;
  invNumber: string;
  hasGst: boolean;
  fromTask: boolean;
  createdAt: string;
  logoUrl: string;
  signatureUrl: string;
}

export let initialParticular: Particular = {
  name: "",
  hsn: "",
  units: 1,
  rate: 0,
  discountType: "PERCENT",
  discount: 0,
  amount: 0,
  gst: null,
};

export const initialState: IState = {
  createdAt: "",
  hasGst: false,
  fromTask: false,
  billingEntity: null,
  locationOfSupply: "",
  billingEntityAddress: null,
  approvalHierarchy: null,
  client: null,
  selectedItems: null,
  placeOfSupply: "",
  estimateNumberType: "AUTO_GENERATED",
  autoGeneratedNumber: "",
  estimateNumber: "",
  estimateDate: null,
  estimateDueDate: null,
  terms: null,
  shippingAddress: null,
  billingAddress: null,
  particulars: [],
  otherParticulars: [],
  bankDetails: null,
  termsAndConditions: [],
  adjustment: 0,
  interState: false,
  whatsappCheck: false,
  emailCheck: false,
  prifix: "",
  invNumber: "",
  logoUrl: "",
  signatureUrl: "",
};
