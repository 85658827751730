import { Divider, Grid, Typography } from "@mui/material";
import converter from "number-to-words";
import { formatToRupees } from "utils/formattedAmount";
import { formateAmountWords } from "utils/formateAmountInWords";
import moment from "moment";

function PaymentSummary({ result }) {
    return (
        <>
            {result.type === "INVOICE" && <Grid container xs={12} mt={3}>
                <Grid item xs={12} mb={2}>
                    <Typography variant="h5">Payment Summary</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SummaryDetail title="Avaliable Credits" value={formatToRupees((result?.avaliableCredits * 1) + (result?.creditsUsed * 1))} />
                </Grid>
                <Grid item xs={12}>
                    <SummaryDetail color={'green'} title="Amount Received" value={formatToRupees(result.amount * 1)} />
                </Grid>
                <Grid item xs={12}>
                    <SummaryDetail title="Available for Adjustment" value={formatToRupees(((result?.avaliableCredits * 1) + ((result?.creditsUsed * 1))) + (result.amount * 1))} />
                </Grid>
                <Grid item xs={12}>
                    <SummaryDetail title="Balance Credits Available" value={formatToRupees(result?.avaliableCredits * 1)} />
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ my: 1, borderWidth: 1, borderColor: "#0D46A0" }} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2 }} alignItems="space-between">
                        <Grid item xs={9}>
                            <Typography variant="h5">Amount Settled</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h5">{formatToRupees((result.amount * 1) + (result.creditsUsed * 1))} /-</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2 }} alignItems="space-between">
                        <Grid item xs={9}>
                            <Typography variant="body2" color='#908A8A'>Total Amount  (In Words)</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body2" color='#908A8A'>
                                Rupees {formateAmountWords(`${converter.toWords((result.amount * 1) + (result.creditsUsed * 1))} only`)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
            {result.type === "ADVANCE" &&
                <Grid container display='flex' sx={{ background: "#0C42950D", borderRadius: 1, mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            sx={{ borderBottom: "1px solid lightgrey", px: 2, py: 1 }}>
                            Summary of Client Advance
                        </Typography>
                    </Grid>
                    <Grid item xs={6} >

                        <Grid />
                        <Grid p={1.5}>
                            <SummaryDetail
                                title="Available Credits"
                                value={formatToRupees(result.previousCredits * 1)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
                            <Grid item xs={5}>
                                <Typography variant="subtitle2">Balance Credits</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="subtitle1">:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="subtitle2">{formatToRupees(result.avaliableCredits * 1)}</Typography>
                            </Grid>
                            <Grid>
                                <span style={{ fontSize: '12px' }}>Last Updated At : {moment(result?.updatedAt).format('DD-MM-YYYY, hh:mm A')}</span>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

        </>


    );
}


const SummaryDetail = ({ title, value, color = '' }) => {
    return (
        <Grid container spacing={2} sx={{ mb: 2 }} alignItems="space-between">
            <Grid item xs={9}>
                <Typography variant="subtitle2" >{title}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography color={color} variant="subtitle2">{value} /-</Typography>
            </Grid>
        </Grid>
    );
};

export default PaymentSummary;