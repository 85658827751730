import { http } from "api/http";

const getOrderNotices = ({ queryKey }: any) => {
  console.log(queryKey);
  return http.get(`/gstr/order-notices/${queryKey[1]}`, { params: { ...queryKey[2] } });
};

const getGstrAdditional = ({ queryKey }: any) => {
  return http.get(`/gstr/additional/${queryKey[1]}`, { params: { ...queryKey[2] } });
};

const getGstrAdditionalNotice = ({ queryKey }: any) => {
  return http.get(`/gstr/additional-notice/${queryKey[1]}`);
};

const getOrderNotice = ({ queryKey }: any) => {
  return http.get(`/gstr/order-notice/${queryKey[1]}`);
};

const getGstrProfile = ({ queryKey }: any) => {
  return http.get(`/gstr/gstr-profile/${queryKey[1]}`);
};

const getAdditionalDocs = ({ querykey }: any) => {
  return http.post(`/gstr/gstr-docs/${querykey[1]}`, { ...querykey[0] });
};

const getGstrClientCompliance = ({ queryKey }: any) => {
  return http.get(`gstr/gstr-compliance/${queryKey[1]}`);
};

const getAddNoticeAndOrders = ({ queryKey }: any) => {
  return http.get("/gstr/gstr-add", { params: { ...queryKey[1] } });
};

const getNoticeAndOrders = ({ queryKey }: any) => {
  return http.get("/gstr/gstr-notices-orders", { params: { ...queryKey[1] } });
};

const getGstrReports = ({ queryKey }: any) => {
  return http.get(`/gstr/reports`, { params: { ...queryKey[1] } });
};


export {
  getOrderNotices,
  getGstrAdditional,
  getOrderNotice,
  getGstrAdditionalNotice,
  getGstrProfile,
  getAdditionalDocs,
  getGstrClientCompliance,
  getAddNoticeAndOrders,
  getNoticeAndOrders,
  getGstrReports,
};
