import { Height, Visibility } from "@mui/icons-material";
import { Box, Breadcrumbs, IconButton, Tooltip, Typography } from "@mui/material"
import { getClientDemand, getClientForm } from "api/services/automation/income-tax";
import Table, { ColumnType } from "components/Table";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { formatToRupeess } from "utils/formattedAmount";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LinkRouter } from "components/BreadCrumbs";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import moment from "moment";

const ClientDemand = () => {
  const params = useParams();
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    return () => {
      //   dispatch(resetFilters());
    };
  }, []);


  const { data, isLoading, error }: ResType = useQuery(
    ["client-demand", params.incometaxid, { search, limit: pageCount, offset: page * pageCount }],
    getClientDemand,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );


  return (
    <>
      <Box p={0.5}>
        {/* <ClientsHeader search={search} setSearch={setSearch} /> */}
        <Table
          sx={{ Height: '450px' }}
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data?.result || []}
          columns={columns}
          loading={isLoading}
        // onRowClick={handleRowClick}
        />
      </Box>
    </>
  );
};


const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === '0' || assessmentYear === '') {
    return 'NA';
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
}

const columns: Array<ColumnType> = [

  {
    key: "assessmentYear",
    title: "Assessment Year",
    render: (v) => (
      <Typography
      // sx={{
      //   backgroundColor: "#000058",
      //   display: "inline-block",
      //   padding: '2px 5px',
      //   border: '1px solid #bb9ce7',
      //   borderRadius: '4px',
      //   color: '#FFFFFF',
      //   fontSize: '12px'
      // }}
      >
        {generateAssessmentYear(v?.assessmentYear)}
      </Typography>
    )

  },
  {
    key: "demandRaisedDate",
    title: (
      <Box display="flex" alignItems="center">
        <DateRangeIcon style={{ width: "20px", marginRight: '5px' }} />Date
      </Box>
    ),
    render: (v) => (
      <Typography
      // sx={{
      //   color: "#222222",
      //   fontSize: '12px'
      // }}
      >
        {moment(v?.demandRaisedDate).format('DD MMM YYYY')}
      </Typography>
    ),
  },
  {
    key: "sectionCodeText",
    title: "Section",
    render: (v) => (
      <Typography
      // sx={{
      //   backgroundColor: "#EDE7FB",
      //   display: "inline-block",
      //   padding: '0px 5px',
      //   border: '1px solid #EDE7FB',
      //   borderRadius: '4px',
      //   color: "#222222",
      //   fontSize: '12px'
      // }}
      >
        {v?.sectionCodeText}
      </Typography>
    ),
  },
  {
    key: "outstandingDemandAmount",
    title: (
      <Box display="flex" alignItems="center">
        Amount(<CurrencyRupeeIcon style={{ width: "15px" }} />)
      </Box>
    ),
    render: (v) => (
      <Tooltip title={`Original Outstanding Demand Amount: ${v?.originalOutstandingDemandAmount} + Accrued Interest: ${v.accruedInterest}`}>
        <Typography
        // sx={{
        //   backgroundColor: "#FBE7E9",
        //   color: "#222222",
        //   display: "inline-block",
        //   padding: '0px 5px',
        //   border: '1px solid #FBE7E9',
        //   borderRadius: '4px',
        //   fontSize: '12px'
        // }}
        >
          <Box display='flex' alignItems='center'>
            <CurrencyRupeeIcon sx={{ width: '15px' }} /> {formatToRupeess(v?.outstandingDemandAmount)}
          </Box>
        </Typography>
      </Tooltip>
    )
  },
  {
    key: '',
    title: 'Actions',
    render: (rowData: any) => <Actions data={rowData} />,

  }

];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/demand/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  )
}


export default ClientDemand