import { DownloadOutlined } from "@mui/icons-material";
import { Divider, Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { downloadInvoice, getInvoicePreview } from "api/services/billing/invoices";
import { vider_logo } from "assets";
import Loader from "components/Loader";
import { placeOfSupplyStates } from "data/placeOfSupply";
import useQueryParams from "hooks/useQueryParams";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";
import BankDetails from "views/billing/estimates/EstimatePreview/BankDetails";
import OtherParticulars from "views/billing/estimates/EstimatePreview/OtherParticulars";
import Particulars from "views/billing/estimates/EstimatePreview/Particulars";
import SectionHeading from "views/billing/estimates/SectionHeading";
import BasicDetails from "views/billing/invoices/InvoicePreview/BasicDetails";
import OrganizationDetails from "views/billing/invoices/InvoicePreview/OrganizationDetails";
import { Watermark } from "views/billing/styles";
import AccessDenied from "./AccressDenied";
import { UserProfileContext } from "context/UserProfile";


export const StyledWatupButton = styled("div")(() => ({
  position: "absolute",
  height: "50px",
  bottom: "30px",
  right: "220px",
  top: "100px",
  zIndex: 100,
  cursor: "pointer"
}));

const InvoicePreview = () => {
  const params = useParams();
  const { queryParams } = useQueryParams();
  const { data: uData } = useContext(UserProfileContext);
  const clientId = localStorage.getItem('clientId');

  const { data, isLoading }: ResType = useQuery(
    ["invoice-details", { invoiceId: params.invoiceId, orgId: uData?.organization?.id, clientId: clientId }],
    getInvoicePreview
  );

  const result = data?.data;

  const placeOfSupplying = placeOfSupplyStates.find(
    (o: any) =>
      (o === result?.placeOfSupply)
  );
  const interState = result?.billingEntity?.locationOfSupply === placeOfSupplying?.split("-")[1];


  if (isLoading) return <Loader />;

  if (result == "Un-Authorized") {
    return (
      <Box></Box>
    )
  }

  return (
    <Box py={2}>
      <Paper
        sx={{
          maxWidth: 1200,
          margin: "auto",
          marginBottom: '0px',
          p: 2,
          pb: 0,
          ...(queryParams.fromApi && {
            boxShadow: "none",
          }),
        }}
      >
        {result?.status === "CANCELLED" && <Watermark imageUrl='https://jss-vider.s3.ap-south-1.amazonaws.com/cancelled.jpg'>
        </Watermark>}
        <StyledWatupButton>
        </StyledWatupButton>
        <OrganizationDetails result={result} />
        <Divider sx={{ my: 1, borderWidth: 1, borderColor: "#0D46A0" }} />
        <BasicDetails result={result} />
        <Particulars result={result} interState={interState} />
        <OtherParticulars result={result} interState={interState} />
        <BankDetails result={result} />
        {result?.termsAndConditionsCopy.length > 0 && <Box mt={2}>
          <SectionHeading title="Terms & Conditions" />
          <Box p={1}>
            {result?.termsAndConditionsCopy?.map((item: any, index: number) => (
              <Typography mt={1} key={index} variant="body2">
                {index + 1}. {item}
              </Typography>
            ))}
          </Box>
        </Box>}
        <Box marginTop='auto' display='flex' justifyContent='center' mt={3}>
          <Typography component='span' variant="h6">Powered by</Typography>
          <img style={{ width: 65, margin: 0, marginLeft: 2 }} src={vider_logo} alt="" />
        </Box>
        <Box textAlign='center' pb={3} color='#908A8A'>
          <Typography variant="body2">This is an electronically generated document.</Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InvoicePreview;