import { Typography } from "@mui/material";
import Members from "components/Members";
import PriorityText from "components/PriorityText";
import Table, { ColumnType } from "components/Table";
import moment from "moment";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { getTitle } from "utils";
import { icons } from "assets";
import useTitle from "hooks/useTitle";

type Props = {
  tab: any;
  data: any;
  totalCount?: number;
  page?: number;
  setPage?: (v: any) => void;
  setPageCount?: (v: any) => void;
  pageCount?: number;
};

function TaskTable({ tab, data, totalCount, page, setPage, pageCount, setPageCount }: Props) {
  const navigate = useNavigate();
  useTitle("Task Board");

  const clientcolumns: Array<ColumnType> = [
    { key: "taskNumber", title: "Task ID" },
    {
      key: "name",
      title: "Task Name",
      render: (row) => (
        <div>
          <span>{row?.name}</span>
          {row.recurring && <img style={{ textAlign: "right", marginLeft: "10px", marginTop: '1px' }} src={icons.recurring} alt="" height={20} width={20} />}
        </div>
      ),
    },
    {
      key: "dueDate",
      title: "Due Date",
      render: (row) => {
        return row?.dueDate ? moment(row?.dueDate).format("DD-MM-YYYY") : "";
      },
    },
    {
      key: "priority",
      title: "Priority",
      render: (v) => <PriorityText text={v?.priority} />,
    },
    {
      key: "taskLeader",
      title: "Task Leaders",
      render: (v) => (
        <Members
          data={v?.taskLeader?.map((item: any) => ({
            title: item?.fullName,
            src: item?.imageStorage?.fileUrl
          }))}
        />
      ),
    },
    {
      key: "Approval Levels",
      title: "Approval Level",
      render: (row) => {
        return <Typography color={row?.approvalStatus?.[0]['status'].includes('Reject') ? 'red' : (row?.approvalStatus?.[0]['status'].includes('Completed') ? "green" : '')}>{row?.approvalStatus?.[0]['status']}</Typography>
      }
    },
    {
      key: "status",
      title: "Status",
      render: (v) => {
        return <Typography variant="body2">{getTitle(v?.status)}</Typography>;
      },
    },
    {
      key: "Memberss",
      title: "Members",
      render: (v) => (
        <Members
          data={v?.members?.map((item: any) => ({
            title: item?.fullName,
            src: item?.imageStorage?.fileUrl
          }))}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        loading={false}
        onRowClick={(v) => navigate(`/tasks/${v?.id}/?clientId=${v?.client?.clientId}`)}
        data={data || []}
        columns={clientcolumns}
        pagination={{
          totalCount: totalCount || 0,
          page: page || 0,
          setPage: setPage || (() => { }),
          pageCount: pageCount || 0,
          setPageCount: setPageCount,
        }}
      />
    </>
  );
}


export default TaskTable;
