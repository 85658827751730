import { Box, Grid, Typography } from "@mui/material";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getGstFinancialYears } from "utils/FinancialYears";
import { RstReturnsCategories } from "data/constants";
import Regular from "./Compliance/regular";
import Collector from "./Compliance/collector";
import Deductor from "./Compliance/deductor";
import EmptyPage from "components/EmptyPage";
import GstrClientDataProvider, { useGstrClientData } from "context/GstrClientData ";

const GstrCompliance = () => {
  useTitle("GSTR Compliance");
  const { data } = useGstrClientData();

  return (
    <Grid container spacing={3} className="password-card-container">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box>
          {data?.data?.gstVerified ? (
            data?.data?.registrationType === RstReturnsCategories.REGULAR_TAXPAYER ? (
              <Regular />
            ) : data?.data?.registrationType === RstReturnsCategories.TAX_COLLECTOR ? (
              <Collector />
            ) : data?.data?.registrationType === RstReturnsCategories.TAX_DEDUCTOR ? (
              <Deductor />
            ) : (
              <Typography>Unknown Registration Type</Typography>
            )
          ) : (
            <EmptyPage
              minHeight="50vh"
              title="For a seamless, smooth, and error-free sync of your Client's GST Returns data, verifying their GSTIN upfront is highly recommended in the profile section. This preliminary step serves as a data integrity check, guaranteeing that the retrieved information pertains to the correct taxpayer. By taking this precaution, you can avoid potential syncing issues that might arise later on."
              // desc="Click on Add Credentials to Add a New One"
              {...(true &&
                {
                  // btn2Title: "Sync To Gst Returns",
                  // btn2Action: () => onClickSync(),
                })}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const GstrComplianceWithProvider = () => (
  <GstrClientDataProvider>
    <GstrCompliance />
  </GstrClientDataProvider>
);

export default GstrComplianceWithProvider;
