import { Box, Breadcrumbs, Divider, Grid, Radio, styled, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import RouterLink from "components/RouterLink";
import useTitle from "hooks/useTitle";
import { useLocation, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { StyledContainer, StyledText1, StyledText2, StyledTitle } from "views/atom-pro/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { formatToRupeess } from "utils/formattedAmount";
import Loader from "components/Loader";
import { getEproceedingData } from "api/services/automation/income-tax";
import moment from "moment";
import EproceedingNoticesList from "./EporceedingNoticesList";

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#fffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

function EproceedingView() {
  useTitle("Atom Pro");
  const params = useParams();
  const location = useLocation();
  const [state, setInitialState] = useState<any>({});

  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["e-proceeding", params.id],
    getEproceedingData,
    {
      enabled: !!params.id,
    }
  );


  useEffect(() => {
    if (data?.data) {
      setInitialState(data?.data);
    }
  }, [data]);


  const backgroundColors = ["#ffffcc", "#ccffcc", "#ffcccc", "#ccccff", "#ffccff", "#f0f8ff", "#f0fff0", "#f5f5dc", "#f5f5f5", "#f5fffa"];
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  if (Object.keys(state).length === 0) return <Typography variant="body2">No data</Typography>;

  const TimelineComponent = ({ dateOfDemandRaised }) => {
    return (
      <Timeline position="left">
        {dateOfDemandRaised.map((item, index) => {
          const { completed, date, status } = item;
          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="body2" component="span">
                  {status}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    backgroundColor: completed ? "#4caf50" : "#ff9800", // Green if completed, orange otherwise
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    boxShadow: "inset 0 0 0 2px #fff",
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {moment(date).format("DD MMM YYYY")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };
  if (demandIsLoading) return <Loader />;

  return (
    <>
      <Box height="80vh" >
        <Box display="flex">
          <Box width="65%" sx={{ borderRight: "1px solid #d9d9d9" }}>
            <Box height="75vh" overflow="auto" mb={2}>
              <Typography
                sx={{ color: "#2F343A", paddingLeft: "10px", marginBottom: "5px" }}
                variant="subtitle1"
              >
                Proceeding Name:{state.proceedingName}
              </Typography>
              <Typography
                sx={{ marginBottom: "15px", color: "#7C848E", paddingLeft: "10px" }}
                variant="subtitle2"
              >
                Acknowledgement No:{state?.acknowledgementNo}
              </Typography>


              <Grid container spacing={2} pl={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>

                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          PAN
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.pan}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          ITR Type
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.itrType ? state?.itrType : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={3}>
                    <Box
                      style={{
                        border: "1.5px solid #d1d1e0",
                        padding: "10px",
                        marginBottom: "10px",
                        borderRadius: "7px",
                      }}
                    >
                      <StyledText1 variant="h6" color="primary" mb={1}>
                        Last Response Date
                      </StyledText1>
                      <StyledSpan
                      // bgColor={getRandomColor()}
                      >
                        {state?.lastResponseSubmittedOn ? moment(state?.lastResponseSubmittedOn).format('DD MMM YYYY') : '--'}
                      </StyledSpan>
                    </Box>
                  </Grid> */}
                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Assessment Year
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {`AY ${state?.assesmentYear}-${parseInt(state?.assesmentYear?.toString().slice(-2)) + 1
                            }`}
                        </StyledSpan>
                      </Box>
                    </Grid>


                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Notice Section
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.noticeName ? state?.noticeName : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                  <Typography variant="subtitle2">Notices</Typography>
                </Box>

                <EproceedingNoticesList data={state?.notices} />


              </Box>
            </Box>
          </Box>

          <Box width="35%" p={2} overflow='auto' height='75vh'>
            <Typography variant="subtitle2" sx={{ color: "#667085" }}>
              Timeline
            </Typography>
            {state && state.proceedingStatusDetails && (
              <TimelineComponent dateOfDemandRaised={state?.proceedingStatusDetails} />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default EproceedingView;
