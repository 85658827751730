import { Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, } from "react-query";
import { useParams } from "react-router-dom";
import { handleError } from "utils/handleError";
import { getTitle } from "utils";
import { formattedDate } from "utils/formattedDate";
import { AddressDetail } from "views/billing/estimates/AddEstimate/BillingEntityDetails";
import { getAddress } from "views/billing/estimates/EditEstimate/BillingEntityDetails";
import SectionHeading from "views/billing/estimates/SectionHeading";
import { downloadInvoice } from "api/services/billing/invoices";
import { DownloadOutlined } from "@mui/icons-material";
import { Permissions } from "data/permissons";

export const StyledDownloadButton = styled("div")(() => ({
    width: "80px",
    height: "50px",
    zIndex: 100,
    cursor: "pointer",
    float: "right",
    fontSize: "10px"
}));

function BasicDetails({ result }) {
    const params = useParams();
    const [isdownloading, setIsdownloading] = useState(false);

    const { mutate } = useMutation(downloadInvoice, {
        onSuccess: (res: any) => {
            const arr = new Uint8Array(res.data?.data);
            console.log(result);
            const blob = new Blob([arr], { type: "application/pdf" });
            const pdf = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = pdf;
            link.download = `${result?.client?.tradeName || result?.client?.displayName} - ${result?.invoiceNumber}.pdf`;
            link.click();
            setIsdownloading(false);
        },
        onError: (err: any) => {
            snack.error(handleError(err));
        },
    });

    const handleDownload = () => {
        setIsdownloading(true);
        mutate({ id: params.invoiceId });
    };

    return (
        <Box>
            <Grid container>
                <Grid item xs={8} textAlign='left' justifyContent='left' >
                    {result?.billingEntity?.logStorage?.fileUrl && (<Box>
                        <img
                            style={{
                                width: "180px",
                                height: "90px",
                                objectFit: "contain",
                            }}
                            src={result?.billingEntity?.logStorage?.fileUrl}
                            alt=""
                        />
                    </Box>)}
                </Grid>
                <Grid item xs={4} textAlign='center'>
                    <Box mt={1} >
                        <Grid container display='flex' flexDirection='row' alignItems='center' xs={12}>
                            <Grid container spacing={2} item xs={11}>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >

                                        <Typography variant="body2">Invoice #</Typography>
                                        <span>:</span>
                                    </Box>
                                </Grid>

                                <Grid item xs={8}>
                                    <Typography variant="h6">
                                        {(result?.invoiceNumber)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="body2">Invoice Date</Typography>
                                        <span>:</span>
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6">
                                        {formattedDate(result?.invoiceDate)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="body2">Due Date</Typography>
                                        <span>:</span>
                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="h6">
                                        {formattedDate(result?.invoiceDueDate)}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid item xs={1}>
                                    <Typography variant="subtitle1" color="#0D46A0">
                                        <StyledDownloadButton id="downloadIcon" className="hide">
                                            {isdownloading ? 'downloading...' : <DownloadOutlined onClick={handleDownload} />}
                                        </StyledDownloadButton>
                                    </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                </Grid>



            </Grid>
        </Box>
    );
}

export default BasicDetails;