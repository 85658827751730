import { Box, Button, Grid, Typography, styled } from "@mui/material";
import {
  checkNoOfSync,
  createRequest,
  getIncomeTaxJurisdiction,
  getIncomeTaxProfile,
  sendActivityData,
} from "api/services/automation/income-tax";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import CachedIcon from "@mui/icons-material/Cached";
import { useEffect, useState } from "react";
import { snack } from "components/toast";
import Loader from "components/Loader";
import { getTitle } from "utils";
import SelectModulePopUp from "./selectModulePopUp";
import moment from "moment";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const axios = require("axios");

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#ffffff",
  padding: "5px",
  fontSize: "14px",
  fontWeight: "500",
  borderRadius: "3px",
}));

const ClientFullView = () => {
  const params = useParams();
  const [jurisdictionDetails, setJurisdictionData] = useState<any>("");
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState<any>("");
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [issueOrReceiveOpen, setIssueOrReceiveOpen] = useState(false);
  const [selectedModules, setSelectedModules] = useState<string[]>([]); // State to hold selected modules
  const [password, setPassword] = useState("YourSecretPassword123!");

  const getItem = localStorage.getItem("automationSyncStatus");

  // const [logs, setLogs] = useState<string[]>([]);
  const { data, isLoading }: ResType = useQuery(
    ["get-income-tax-profile", params.incometaxid],
    getIncomeTaxProfile,
    {
      onSuccess: (res: any) => {
        if (res?.data?.profileDetails) {
          setProfileData(res?.data?.profileDetails);
          setPassword(
            `${res?.data?.profileDetails?.pan?.toLowerCase()}${moment(
              res?.data?.profileDetails?.dob
            ).format("DDMMYYYY")}`
          );
        }

        if (res?.data?.lastCompletedMachine) {
          if (res?.data?.lastCompletedMachine?.remarks !== "Success") {
            snack.error(res?.data?.lastCompletedMachine?.remarks);
          }
        }
      },
      cacheTime: 0,
    }
  );

  const { data: jurisdictionData, isLoading: isJurisdictionLoading }: ResType = useQuery(
    ["get-income-tax-jurisdiction", params.incometaxid],
    getIncomeTaxJurisdiction,
    {
      onSuccess: (res: any) => {
        if (res?.data) {
          setJurisdictionData(res?.data);
        }
      },
      cacheTime: 0,
    }
  );

  const [syncStatus, setSyncStatus] = useState(true);
  const { data: data1, isLoading: isLoading1 }: ResType = useQuery(
    ["check-No-Of-Sync"],
    checkNoOfSync,
    {
      onSuccess: (res) => {
        if (res?.data === 0) {
          setSyncStatus(false);
        } else {
          setSyncStatus(true);
        }
      },
    }
  );

  //*****DONT REMOVE*****
  // useEffect(() => {
  //   const client = new WebSocket("ws://localhost:3006");
  //   client.onopen = () => {
  //     console.log("WebSocket connection established");
  //   };
  //   client.onmessage = (message) => {
  //     console.log("Received message:", message.data);
  //     setLogs((prevLogs) => [...prevLogs, message.data]);
  //     // Update logs state with received message
  //   };
  //   return () => {
  //     client.close(); // Close WebSocket connection when component unmounts
  //   };
  // }, []);

  const { mutate: sendRequest, isLoading: LLLLLL } = useMutation(createRequest, {
    onSuccess: (res) => {
      if (res?.data) {
        if (res?.data?.startsWith("Successfully")) {
          snack.success(res?.data);
        } else {
          snack.error(res?.data);
        }
        setTimeout(() => {
          navigate(`/atom-pro/income-tax/incometax/${params.incometaxid}/client-status`);
        }, 1000);
      } else {
        snack.error(res?.data);
      }
    },
  });

  const { mutate, isLoading: activityLoading } = useMutation(sendActivityData, {
    onSuccess: (res) => {
      setStartTime(new Date());
      setLoading(true);
      localStorage.setItem("automationSyncStatus", "start");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: `http://localhost:3006/${params.incometaxid}`,
        url: `https://atompro.vider.in/${params.incometaxid}`,
        headers: {},
        data: { requests: selectedModules },
      };
      setSelectedModules([]);
      setIssueOrReceiveOpen(false);
      axios
        .request(config)
        .then((response) => {
          localStorage.removeItem("automationSyncStatus");
          if (response.data === "Automation Completed") {
            setEndTime(new Date());
            snack.success("Automation completed");
          }
          setLoading(false);
          setTimeout(() => {
            queryClient.invalidateQueries("get-income-tax-profile");
          }, 500);
        })
        .catch((error) => {
          setLoading(false);
          localStorage.removeItem("automationSyncStatus");
          snack.error("Automation failed Please try again");
          console.log(error);
        });
    },
    onError: (err: any) => {
      if (err && err?.response && err?.response?.data && err?.response?.data?.message) {
        snack.error(err.response.data.message);
      } else if (!err.error) {
        snack.error("An error occurred while creating Client");
      }
    },
  });

  const handleSyncc = () => {
    setIssueOrReceiveOpen(true);
  };

  const handleIncomeTaxSync = () => {
    sendRequest({
      id: params.incometaxid,
      data: {
        requests: selectedModules,
      },
    });
    setIssueOrReceiveOpen(false);
  };

  const calculateXs = (value: string, key: any) => {
    const length = value.toString().length;

    if (key === "address") {
      return 12;
    }
    if (length <= 25) {
      return 3;
    } else if (length <= 5) {
      return 1;
    } else if (length >= 25) {
      return 4;
    } else if (length <= 100) {
      return 6;
    } else {
      return 12;
    }
  };

  const backgroundColors = [
    "#ffffcc",
    "#ccffcc",
    "#ffcccc",
    "#ccccff",
    "#ffccff",
    "#f0f8ff",
    "#f0fff0",
    "#f5f5dc",
    "#f5f5f5",
    "#f5fffa",
  ];
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  function convertToReadableString(str) {
    // Insert spaces before each uppercase letter except for the first one
    let readableStr = str.replace(/([A-Z])/g, " $1").trim();
    // Capitalize the first letter
    if (readableStr === "dob") {
      return "Date of Birth | Incorporation";
    } else if (readableStr === "pan") {
      return "PAN";
    } else if (readableStr === "pan Status") {
      return "PAN Status";
    } else if (readableStr === "ao Type") {
      return "AO Type";
    } else if (readableStr === "ao Number") {
      return "AO Number";
    } else if (readableStr === "email Id") {
      return "Primary Email Id";
    }
    readableStr = readableStr.charAt(0).toUpperCase() + readableStr.slice(1);
    return readableStr;
  }

  const handleSelectionChange = (selectedValues: string[]) => {
    setSelectedModules(selectedValues);
  };

  if (loading)
    return (
      <>
        {/* <Box mt={2}>
          <Grid container spacing={2}>
            {logs.map((log, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="body1">{log}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box> */}
        <Loader value="Automation is in progress.... Please wait" />
      </>
    );
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(password);
      toast.success("Password copied");
    } catch (err) {
      console.error("Failed to copy: ", err);
      toast.error("Failed to copy password.");
    }
  };

  return (
    <Box>
      <Box height="70vh" overflow="auto">
        <Box>
          <Grid container>
            <Grid item xs={7}>
              {/* <Typography variant="subtitle1" color="primary">
                Client Details
              </Typography> */}
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={copyToClipboard}
                startIcon={<ContentCopyIcon />}
              >
                Password
              </Button>
            </Grid>
          </Grid>
          <SelectModulePopUp
            open={issueOrReceiveOpen}
            setOpen={setIssueOrReceiveOpen}
            onSelectionChange={handleSelectionChange}
            onSync={handleIncomeTaxSync}
          />
          {profileData === "" ? (
            <Box textAlign="center" mt={20}>
              <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                No Data present
              </Typography>
            </Box>
          ) : (
            <Box m={1}>
              <Grid container spacing={1} mb={2}>
                {Object.keys(profileData).map((key: any) => (
                  <>
                    {profileData[key] !== null &&
                      ![
                        "updatedAt",
                        "id",
                        "ClientId",
                        "createdAt",
                        "primaryNumber",
                        "secondaryNumber",
                        "emailId",
                        "address",
                        "secondaryEmailId",
                      ].includes(key) && (
                        <Grid item xs={calculateXs(profileData[key], key)} key={key}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "5px",
                              marginBottom: "5px",
                              borderRadius: "7px",
                            }}
                          >
                            <Typography variant="body1" color="primary" mb={1}>
                              {convertToReadableString(key)}
                            </Typography>
                            <StyledSpan
                            //  bgColor={getRandomColor()}
                            >
                              {profileData[key]}
                            </StyledSpan>
                          </Box>
                        </Grid>
                      )}
                  </>
                ))}
              </Grid>

              <Typography variant="subtitle1" color="primary" mb={1}>
                Contact Info
              </Typography>

              <Grid container spacing={1} mb={2}>
                {Object.keys(profileData).map((key: any) => (
                  <>
                    {profileData[key] !== null &&
                      ![
                        "updatedAt",
                        "id",
                        "ClientId",
                        "createdAt",
                        "name",
                        "dob",
                        "pan",
                        "gender",
                        "residentialStatus",
                        "aadharNumberLinkStatus",
                        "citizenship",
                        "panStatus",
                        "",
                      ].includes(key) && (
                        <Grid item xs={calculateXs(profileData[key], key)} key={key}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "5px",
                              marginBottom: "5px",
                              borderRadius: "7px",
                            }}
                          >
                            <Typography variant="body1" color="primary" mb={1}>
                              {convertToReadableString(key)}
                            </Typography>
                            <StyledSpan
                            // bgColor={getRandomColor()}
                            >
                              {profileData[key]}
                            </StyledSpan>
                          </Box>
                        </Grid>
                      )}
                  </>
                ))}
              </Grid>

              <Typography variant="subtitle1" color="primary" mb={1}>
                Jurisdiction Details
              </Typography>

              <Grid container spacing={1}>
                {Object.keys(jurisdictionDetails).map((key: any) => (
                  <>
                    {jurisdictionDetails[key] !== null &&
                      !["pan", "updatedAt", "id", "clientId", "createdAt"].includes(key) && (
                        <Grid item xs={calculateXs(jurisdictionDetails[key], key)} key={key}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "5px",
                              marginBottom: "5px",
                              borderRadius: "7px",
                            }}
                          >
                            <Typography variant="body1" color="primary" mb={1}>
                              {convertToReadableString(key)}
                            </Typography>
                            <StyledSpan
                            // bgColor={getRandomColor()}
                            >
                              {jurisdictionDetails[key]}
                            </StyledSpan>
                          </Box>
                        </Grid>
                      )}
                  </>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ClientFullView;
