import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BankDetail } from "views/billing/estimates/AddEstimate/BankDetails";
import SectionHeading from "views/billing/estimates/SectionHeading";

interface IProps {
  result: any;
}

function BankDetails({ result }: IProps) {
  return (
    <Box mt={3} >
      <SectionHeading title="Bank Account Details" />
      <Grid container xs={12} spacing={2} sx={{ p: 2, mt: "0px" }}>
        <Grid container item xs={7} sx={{
          backgroundColor: "#f3f5fa",
        }}>
          <Grid item xs={8}>
            {result?.bankDetails && (
              <>
                <BankDetail
                  title="Bank Name"
                  value={result?.bankDetails.bankName}
                />
                <BankDetail
                  title="Bank Branch"
                  value={result?.bankDetails.branchName}
                />
                <BankDetail
                  title="Bank Account Number"
                  value={result?.bankDetails.accountNumber}
                />
                <BankDetail
                  title="IFSC Code"
                  value={result?.bankDetails.ifscCode}
                />
                {result?.bankDetails.upiId && <BankDetail title="UPI ID" value={result?.bankDetails.upiId} />}
              </>
            )}

          </Grid>
          {result?.bankDetails?.upiAttachment && <Grid item xs={4} textAlign='center' mt={6}>
            <img
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
              }}
              src={result?.bankDetails?.upiAttachment}
              alt=""
            />
          </Grid>}

        </Grid>
        {result?.billingEntity?.signatureStorage?.fileUrl && (
          <Grid item xs={5} sx={{ textAlign: "center", marginTop: 'auto' }}>
            <img
              style={{
                width: "200px",
                height: "100px",
                objectFit: "contain",
              }}
              src={result?.billingEntity?.signatureStorage?.fileUrl}
              alt=""
            />
            <Typography variant="h6" color="#172F51">Authorised Signatory</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default BankDetails;
