import { Box, Breadcrumbs, Divider, Grid, Radio, styled, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import RouterLink from "components/RouterLink";
import useTitle from "hooks/useTitle";
import { useLocation, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import { StyledContainer, StyledText1, StyledText2, StyledTitle } from "views/atom-pro/styles";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { formatToRupeess } from "utils/formattedAmount";
import Loader from "components/Loader";
import { getEproceedingData, getEproceedingFyiData } from "api/services/automation/income-tax";
import moment from "moment";
import EproceedingNoticesList from "./EporceedingNoticesList";
import EproceedingFyiNoticesList from "./EporceedingFyiNoticesList";
import { Margin } from "@mui/icons-material";

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#ffffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

const handleItemClick = (fileName, type) => {
  const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
  const amazon = type == "AMAZON" || type == null
  const url = amazon ? (urlStart + fileName) : fileName?.key;
  window.open(url, '_blank');
};

const getFileName = (filePath, type) => {
  // Split the filePath by '/' and get the last part which is the file name
  if (type == 'AMAZON' || type == null) {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  } else if (type == 'MICROSOFT') {
    const parts = filePath['name'].split('/');
    return parts[parts.length - 1];
  };
  return null

};


function EproceedingFyiView() {
  useTitle("E-Proceedings (For Your Information)");
  const params = useParams();
  const location = useLocation();
  const [state, setInitialState] = useState<any>({});

  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["e-proceeding", params.id],
    getEproceedingFyiData,
    {
      enabled: !!params.id,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setInitialState(data?.data);
    }
  }, [data]);

  const backgroundColors = ["#ffffcc", "#ccffcc", "#ffcccc", "#ccccff", "#ffccff", "#f0f8ff", "#f0fff0", "#f5f5dc", "#f5f5f5", "#f5fffa"];
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  if (Object.keys(state).length === 0) return <Typography variant="body2">No data</Typography>;

  const TimelineComponent = ({ dateOfDemandRaised }) => {
    return (
      <Timeline position="left">
        {dateOfDemandRaised.map((item, index) => {
          const { completed, date, status } = item;
          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="body2" component="span">
                  {status}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  sx={{
                    backgroundColor: completed ? "#4caf50" : "#ff9800", // Green if completed, orange otherwise
                    borderRadius: "50%",
                    width: "16px",
                    height: "16px",
                    boxShadow: "inset 0 0 0 2px #fff",
                  }}
                />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {moment(date).format("DD MMM YYYY")}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };
  if (demandIsLoading) return <Loader />;

  return (
    <>

      <Box display="flex" height="80vh">
        <Grid container>
          <Grid item xs={8} sx={{ borderRight: "1px solid #d9d9d9" }}>
            <Box height="80vh" overflow="auto" mb={2}>
              <Typography
                sx={{ marginBottom: "20px", color: "#2F343A", paddingLeft: "10px" }}
                variant="subtitle1"
              >
                {state?.proceedingName}
              </Typography>
              <Typography
                sx={{ marginBottom: "20px", color: "#7C848E", paddingLeft: "10px" }}
                variant="subtitle2"
              >
                Acknowledgement No:{state?.acknowledgementNo}
              </Typography>

              <Grid container spacing={2} pl={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          ITR Type
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.itrType ? state?.itrType : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Last Response Date
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.lastResponseSubmittedOn ? moment(state?.lastResponseSubmittedOn).format('DD MMM YYYY') : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        style={{
                          border: "1.5px solid #d1d1e0",
                          padding: "10px",
                          marginBottom: "10px",
                          borderRadius: "7px",
                        }}
                      >
                        <StyledText1 variant="h6" color="primary" mb={1}>
                          Notice Section
                        </StyledText1>
                        <StyledSpan
                        // bgColor={getRandomColor()}
                        >
                          {state?.noticeName ? state?.noticeName : '--'}
                        </StyledSpan>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box>
                <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                  <Typography variant="subtitle2">Notices</Typography>
                </Box>

                <EproceedingFyiNoticesList data={state?.notices} />

                <Box mb={2}>
                  <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                    <Typography variant="subtitle2">Closure Order Attachments</Typography>
                  </Box>
                  {state?.closureOrderAttatchments && state?.closureOrderAttatchments.map((detail: any, index: number) => (
                    <Box display="flex" textAlign="center">
                      <Typography style={{ padding: '5px', margin: '5px' }}>{index + 1}.</Typography>
                      <div
                        key={index}
                        style={{ cursor: 'pointer', padding: '5px', margin: '5px', border: '1px solid #ccc', borderRadius: '5px', color: "blue" }}
                        onClick={() => handleItemClick(detail, state?.storageSystem)}
                      >
                        {getFileName(detail, state?.storageSystem)}
                      </div>
                    </Box>

                  ))}
                  {/* <ul style={{ listStyleType: "none", paddingBottom: '3px', padding: '2px', margin: '0', overflowWrap: 'break-word' }}>
                    {state?.closureOrderAttatchments.map((attachment, index) => {
                      const parts = attachment.split("/");
                      const fileName = parts[parts.length - 1];
                      const url = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/${attachment}`;
                      return (
                        <li key={index} style={{ marginBottom: '8px' }}>
                          <span>{index + 1}. </span>
                          <a href={url} rel="noopener noreferrer" style={{ wordBreak: 'break-all' }}>
                            {fileName}
                          </a>
                        </li>
                      );
                    })}
                  </ul> */}
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2" sx={{ color: "#667085" }}>
              Timeline
            </Typography>
            {state && state.proceedingStatusDetails && (
              <TimelineComponent dateOfDemandRaised={state?.proceedingStatusDetails} />
            )}

          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default EproceedingFyiView;
