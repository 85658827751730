import { http } from "api/http";

const getOrganization = () => {
  return http.get(`/organization`);
};

const getGetStarted = () => {
  return http.get(`/get-started`);
};

const getOrganizationLicenses = ({ queryKey }) => {
  return http.get(`/organization-lincenses`, {
    params: {
      ...queryKey[1],
    },
  });
};

const getBankAccounts = ({ queryKey }) => {
  return http.get(`/bank-accounts`, {
    params: {
      ...queryKey[1],
    },
  });
};

const getTaskAnalytics = ({ queryKey }) => {
  return http.get(`/stats/task-analytics`, { params: { ...queryKey[1] } });
};

const getTaskBoardAnalytics = ({ queryKey }) => {
  return http.get(`/tasks/task-analytics`, { params: { ...queryKey[1] } });
};

const getTasksDueThisWeek = ({ queryKey }) => {
  return http.get(`/stats/tasks-due-this-week`, { params: { ...queryKey[1] } });
};

const getTasksByCategory = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-category`, { params: { ...queryKey[1] } });
};

const getTasksByCategoryExport = (data) => {
  return http.post(`/stats/tasks-by-category-export`, data);
};

const getTasksByClientCategory = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-client-category`, { params: { ...queryKey[1] } });
};

const getTasksByClientCategoryExport= (data) => {
  return http.get(`/stats/tasks-by-client-category-export`, data);
};

const getTasksByService = ({ queryKey }) => {
  return http.get(`/stats/tasks-by-service`, { params: { ...queryKey[1] } });
};

const exportTasksByService = (data) => {
  return http.post(`/stats/tasks-by-service-export`, data);
};

const getOverdueTasks = ({ queryKey }) => {
  return http.get(`/stats/over-due-tasks`, { params: { ...queryKey[1] } });
};

const exportOverDueTask = (data) => {
  return http.post(`/stats/over-due-tasks-export`, data);
};

const getClientAnalytics = () => {
  return http.get(`/stats/client-analytics`);
};

const getClientBillingAnalytics = ({ queryKey }) => {
  return http.get(`/stats/client-billing-analytics`, { params: { ...queryKey[1] } });
}

const getDueDscRegisters = ({ queryKey }) => {
  return http.get(`/stats/due-dsc-registers`, { params: { ...queryKey[1] } });
};

const getClientsByCategory = () => {
  return http.get(`/stats/clients-by-category`);
};

const getClientsByCategoryexport = (data) => {
  return http.post(`/stats/clients-by-category-export`,data);
};

const getTotalLogHours = ({ queryKey }) => {
  return http.get(`/stats/total-log-hours`, { params: { ...queryKey[1] } });
};

const getWeeklyLogHours = ({ queryKey }) => {
  return http.get(`/stats/weekly-log-hours`, { params: { ...queryKey[1] } });
};

const getEmployeeTasksByStatus = ({ queryKey }) => {
  return http.get(`/stats/employee-tasks-by-status`, { params: { ...queryKey[1] } });
};

const exportEmployeeTasksByStatus = (data) => {
  return http.post(`/stats/employee-tasks-by-status-report`, data);
};

const getDailyAttendance = ({ queryKey }) => {
  return http.get(`/stats/employee-attendance`, { params: { ...queryKey[1] } });
};

const getSmtp = () => {
  return http.get(`/organization/smtp`);
};

const getUdinUsers = ({ queryKey }) => {
  return http.get('client-panel/udin-users', { params: { clientId: queryKey[2] }})
}

export {
  exportOverDueTask,
  getTasksByClientCategoryExport,
  getTasksByCategoryExport,
  getClientsByCategoryexport,
  exportEmployeeTasksByStatus,
  exportTasksByService,
  getOrganization,
  getOrganizationLicenses,
  getBankAccounts,
  getTaskAnalytics,
  getGetStarted,
  getTasksDueThisWeek,
  getTasksByCategory,
  getTasksByClientCategory,
  getTasksByService,
  getOverdueTasks,
  getClientAnalytics,
  getDueDscRegisters,
  getClientsByCategory,
  getTotalLogHours,
  getWeeklyLogHours,
  getEmployeeTasksByStatus,
  getTaskBoardAnalytics,
  getSmtp,
  getDailyAttendance,
  getClientBillingAnalytics,
  getUdinUsers
};
