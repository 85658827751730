import { Box } from "@mui/material";
import useTitle from "hooks/useTitle";
import { useState } from "react";
import EproceedingFya from "views/atom-pro/eProceedingFYA";
import EproceedingFyi from "views/atom-pro/eProceedingFYI";
import { StyledProfileNavItem } from "views/ClientProfile/styles";

const IncomeTaxEProceedings = () => {
  useTitle("ATOM Pro | e-Proceedings");
  const [active, setActive] = useState("FYA");

  return (
    <>
      <Box
        mb={1}
        display="flex"
        gap="15px"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        bgcolor="#F5F5F5"
      >

        <StyledProfileNavItem
          variant="body1"
          color="rgba(0,0,0,0.7)"
          active={active === "FYA" ? 1 : 0}
          onClick={() => setActive("FYA")}
          sx={{ marginLeft: "10px", }}
        >
          For Your Action
        </StyledProfileNavItem>
        <StyledProfileNavItem
          variant="body1"
          color="rgba(0,0,0,0.7)"
          active={active === "FYI" ? 1 : 0}
          onClick={() => setActive("FYI")}
        >
          For Your Information
        </StyledProfileNavItem>


      </Box>

      {active === "FYA" && <EproceedingFya tab="FYA" />}
      {active === "FYI" && <EproceedingFyi tab="FYI" />}

      {/* <Box pl={1} pr={1} mt={0} pt={0} >
        <Box pl={2} pr={2} mb={1} mt={1} pt={0} pb={0} display="flex" justifyContent="center" alignItems="center" bgcolor="#F5F5F5">
          <Box display="flex" gap="15px" flexWrap="wrap" alignItems="center" >
            <StyledClientFilterItem
              variant="body1"
              color="rgba(0,0,0,0.7)"
              active={active === "FYA" ? 1 : 0}
              onClick={() => setActive("FYA")}
            >
              For Your Action
            </StyledClientFilterItem>
            <StyledClientFilterItem
              variant="body1"
              color="rgba(0,0,0,0.7)"
              active={active === "FYI" ? 1 : 0}
              onClick={() => setActive("FYI")}
            >
              For Your Information
            </StyledClientFilterItem>
          </Box>
        </Box>

      </Box> */}

    </>
  )
}

export default IncomeTaxEProceedings;





