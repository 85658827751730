import { http } from "api/http";

const getInvoices = ({ queryKey }) => {
  return http.get(`/invoices/client-panel/${queryKey[1].clientId}`, { params: { ...queryKey[1] } });
};

const getClientInvoices = ({ queryKey }) => {
  return http.get(`/invoices/${queryKey[1]}`);
};

const getClientBillingInvoices = ({ queryKey }) => {
  return http.get(`/invoices/client-bill`, { params: { ...queryKey[1] } });
}

const getInvoice = ({ queryKey }) => {
  return http.get(`/invoices/${queryKey[1]}`);
};

const getInvoicePreview = ({ queryKey }) => {
  const { invoiceId, orgId, clientId } = queryKey[1]
  return http.get(`/invoices/${invoiceId}/preview`, { params: { orgId: orgId, clientId: clientId } });
};

const downloadInvoice = ({ id }) => {
  return http.post(`/invoices/${id}/download`);
};

const downloadInvoicewithoutEmittor = ({ id }) => {
  return http.post(`/invoices/${id}/downloadwithoutemittor`);
};

const exportInvoices = () => {
  return http.post(`/invoices/export`);
};

export {
  getInvoices,
  getInvoicePreview,
  getClientInvoices,
  downloadInvoice,
  exportInvoices,
  downloadInvoicewithoutEmittor,
  getClientBillingInvoices
};