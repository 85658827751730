import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import { StyledParticularsTable } from "views/billing/styles";
import moment from "moment";
import { formatToRupees } from "utils/formattedAmount";
import { paymentMethods } from "data/constants";

function PaymentDetails({ result }) {
    return (
        <Box >
            <TableContainer>
                <StyledParticularsTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Date
                            </TableCell>

                            <TableCell>
                                Receipt Mode
                            </TableCell>

                            <TableCell>
                                Reference Number
                            </TableCell>
                            <TableCell>
                                Amount Received
                            </TableCell>

                        </TableRow>

                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {moment(result?.paymentDate).format('DD-MM-YYYY').toString()}
                            </TableCell>
                            <TableCell>
                                {paymentMethods[result?.paymentMode]}
                            </TableCell>
                            <TableCell>
                                {result?.referenceNumber}
                            </TableCell>
                            <TableCell>
                                {formatToRupees((+(result?.amount * 1) || 0) + (+(result?.creditsUsed) * 1 || 0))}
                            </TableCell>
                        </TableRow>
                    </TableBody>

                </StyledParticularsTable>

            </TableContainer>

        </Box>


    );
}

export default PaymentDetails;