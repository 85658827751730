import { Box, Button, Typography } from "@mui/material";
import { downloadInvoice, downloadInvoicewithoutEmittor, getInvoices } from "api/services/billing/invoices";
import Table from "components/Table";
import useTitle from "hooks/useTitle";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ResType } from "types";
import { getTitle } from "utils";
import { formattedDate } from "utils/formattedDate";
import { getStatusColor } from "views/billing/estimates/getStatusColor";
import InvoicesHeader from "views/billing/invoices/InvoicesHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { snack } from "components/toast";
import { Permissions } from "data/permissons";
import moment from "moment";
import { formatToRupees, formattedAmount } from "utils/formattedAmount";

import { invoiceReminder } from "api/services/common";
import { UserProfileContext } from "context/UserProfile";

const Estimates = () => {
  useTitle("Invoices");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [selected, setSelected] = useState<any[]>([]);
  const dispatch = useDispatch();
  const clientId = localStorage.getItem('clientId');

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const { data, isLoading }: ResType = useQuery(
    ["invoices", { offset: page * pageCount, limit: pageCount, search, clientId: clientId  }],
    getInvoices, {
    onError: (err) => {
      if (!err?.error) {
        snack.error(`An error occurred while fetching invoices`);
      }
    }
  }
  );

  const handleRowClick = (v: any) => {
    window.open(`/billing/invoices/${v?.id}/preview`);
  };

  let columns: any = [
    {
      key: "invoiceNumber",
      title: "Invoice #",
    },
    {
      key: "billingEntity.tradeName",
      title: "Billing Entity",
      width: "20%",
    },
    {
      key: "client.displayName",
      title: "Client Name",
    },
    {
      key: "grandTotal",
      title: "Invoice Amount",
      render: (row: any) => formattedAmount(row.grandTotal)
    },
    {
      key: "invoiceDate",
      title: "Invoice Date",
      render: (row: any) => formattedDate(row.invoiceDate),

    },
    {
      key: "invoiceDueDate",
      title: "Due Date",
      render: (row: any) => formattedDate(row.invoiceDueDate),

    },
    {
      key: "status",
      title: "Status",
      render: (row: any) => (
        <Box
          sx={{
            background: getStatusColor(((row?.status == 'APPROVAL_PENDING' || row?.status == 'PARTIALLY_PAID') && (row?.invoiceDueDate <= moment().subtract(1, "day").format("YYYY-MM-DD"))) ? "OVERDUE" : row?.status),
            px: 2,
            py: "4px",
            color: "white",
            borderRadius: "10px",
            textAlign: "center",
            display: "inline-flex",
          }}
        >
          <Typography variant="body2">
            {row?.status == 'APPROVAL_PENDING' || row?.status == 'PARTIALLY_PAID' ? (row?.invoiceDueDate > moment().subtract(1, 'day').format("YYYY-MM-DD") ? (row?.status == 'APPROVAL_PENDING' ? getTitle('Created') : getTitle(row?.status?.toLowerCase())) : getTitle('overdue')) : getTitle(row?.status?.toLowerCase())}
          </Typography>
        </Box>
      ),
    },
  ];

  const setSearchChange = (e) => {
    setSearch(e);
    setPage(0);
  };

  const totalCount = data?.data?.totalCount || 0;

  return (
    <Box p={3} pl={1} pr={1} pb={0}>

        <InvoicesHeader
          clearSelection={() => setSelected([])}
          selected={selected}
          search={search}
          setSearch={setSearchChange}
        />
        <Table
          sx={{ height: 'calc(75vh - 10px)', mb: 0, pb: 0 }}
          selection={{ selected, setSelected }}
          pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
          data={data?.data?.result || []}
          columns={columns}
          loading={isLoading}
          onRowClick={handleRowClick}
        />
    </Box>
  );
};



export default Estimates;
