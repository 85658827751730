import { http } from "api/http";
import { QueryType } from "api/types";

const getTasks = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/tasks", {
    params: {
      ...queryKey[1],
      limit,
      offset,
      ...query,
    },
  });
};

const getTask = ({ queryKey }: any) => {
  return http.get(`/tasks/task-details/${queryKey[1]}`, { params: { clientId: queryKey[2] } });
};

const getTaskCollectData = ({ queryKey }: any) => {
  return http.get(`/collect-data/task/${queryKey[1]}`);
};

const getChecklists = ({ queryKey }: any) => {
  return http.get(`/tasks/checklists`, { params: { taskId: queryKey[1] } });
};

const getStageOfWork = ({ queryKey }: any) => {
  return http.get(`/tasks/stage-of-work`, { params: { taskId: queryKey[1] } });
};

const getTaskAttachments = ({ queryKey }: any) => {
  return http.get(`/tasks/attachments`, { params: { taskId: queryKey[1] } });
};

const getLogHours = ({ queryKey }: any) => {
  return http.get(`/log-hours`, { params: { taskId: queryKey[1] } });
};

const getBudgetedHours = ({ queryKey }: any) => {
  return http.get(`/budgeted-hours`, { params: { taskId: queryKey[1] } });
};

const getApprovalLevels = ({ queryKey }) => {
  return http.get("/approval-level/tasks", { params: { ...queryKey[1] } });
};

const getEvents = ({ queryKey }) => {
  return http.get("/events", { params: { ...queryKey[1] } });
};

const createdTemplates = ({ queryKey }) => {
  return http.get(`/quantum/createdTemplates`, { params: { ...queryKey[1] } });
};

const downloadQuantumDocument = (data: any) => {
  return http.post("/quantum/downlaod-document", data);
};

const getPreviewDetails = ({ queryKey }: any) => {
  return http.get(`/quantum/getPreviewDetails/${queryKey[1]}`);
};

const getQuantumConfig = () => {
  return http.get("/quantum/config");
};

const getExpenditure = ({ queryKey }) => {
  let { limit, offset, query } = queryKey[1] || {};

  return http.get("/tasks/expenditure", {
    params: { ...queryKey[1] },
    limit,
      offset,
      ...query
  });
};

const getUdinTaskDetails = ({ queryKey }: QueryType) => {
  return http.get(`/udin-task/${queryKey[1]}`, { params: { taskId: queryKey[1], clientId: queryKey[2] } });
};

export {
  getTasks,
  getTask,
  getTaskCollectData,
  getChecklists,
  getStageOfWork,
  getTaskAttachments,
  getLogHours,
  getBudgetedHours,
  getApprovalLevels,
  getEvents,
  createdTemplates,
  downloadQuantumDocument,
  getPreviewDetails,
  getQuantumConfig,
  getExpenditure,
  getUdinTaskDetails,
};
