import { Close } from "@mui/icons-material";
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";

interface IDrawerWrapperProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
  width?: number | string; // Modify width prop to accept number or string
  height?: number | string; // Add height prop to accept number or string

}

function DrawerWrapper({ open, setOpen, title, children, height = '100vh' }: IDrawerWrapperProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const screenwidth = isSmallScreen ? '100%' : 500; // Set width based on screen size
  const paddingLeftMobile = isSmallScreen ? 3 : 0; // Adjust left padding on mobile devices
  const paddingRightMobile = isSmallScreen ? 3 : 0; // Adjust left padding on mobile devices
  const drawerHeight = isSmallScreen ? '100vh' : height; // Set height based on screen size or provided height
  const drawerWidth = isSmallScreen ? '100vw' :  500; // Set width based on screen size or provided width

  const handleClose = () => setOpen(false);

  return (
    <Drawer
      anchor="right"
      PaperProps={{ sx: { width: drawerWidth, height: drawerHeight, paddingLeftMobile ,screenwidth} }} // Apply left padding conditionally

      open={open}
      onClose={handleClose}
    >
      <AppBar position="sticky">
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center", padding: isSmallScreen ? '0px 5px 0px 0px' : '0 16px' }}>
          <Typography variant="subtitle1" sx={{ padding: isSmallScreen ? '0 65px' : '' }} >{title}</Typography>
          <IconButton onClick={() => setOpen(false)} sx={{
            color: "white",
            paddingLeft: isSmallScreen ? '0px' : '8px',
            fontSize: isSmallScreen ? 'small' : 'default'
          }}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          paddingTop: isSmallScreen ? 2 : 2,
          paddingLeft: isSmallScreen ? 8 : 2,
          paddingRight: isSmallScreen ? 4 : 2,
          overflow: 'scroll'
        }}
      >
        {children}
      </Box>
    </Drawer>
  );
}

export default DrawerWrapper;
