import { Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { handleError } from "utils/handleError";
import { getAddress } from "views/billing/estimates/EditEstimate/BillingEntityDetails";
import { downloadInvoice } from "api/services/billing/invoices";
import { showAddress } from "views/billing/estimates/AddEstimate/ClientDetails";
import SectionHeading from "./SectionHeading";

export const StyledDownloadButton = styled("div")(() => ({
    width: "80px",
    height: "50px",
    zIndex: 100,
    cursor: "pointer",
    float: "right",
    fontSize: "10px"
}));

function BasicDetails({ result }) {
    return (
        <Box >
            <Box textAlign='center'>
                <Typography variant="subtitle1">
                    Payment Receipt
                </Typography>
            </Box>
            <Grid container xs={12}>
                <Grid item xs={6} >
                    <Box>
                        <Grid container spacing={3} justifyContent="space-between">
                            <Grid item xs={11.5}>

                                <SectionHeading title="Issued By" color="white" />
                                <Box p={1} bgcolor={'#F8F6F6'} >
                                    <Grid>
                                        <Typography variant="h6" color="#0D46A0"> {result?.billingEntity?.tradeName}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2">{getAddress(result?.billingEntity)}</Typography>
                                    </Grid>
                                    {result?.billingEntity?.gstNumber && <Grid>
                                        <Typography variant="body2">{result?.billingEntity?.gstNumber}</Typography>
                                    </Grid>}
                                    {result?.billingEntity?.email && <Grid>
                                        <Typography variant="body2">{result?.billingEntity?.email}</Typography>
                                    </Grid>}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box >
                        <Grid container spacing={3}>
                            <Grid item xs={11.5}>
                                <SectionHeading title="Issued To" color="white" />
                                <Box p={2} bgcolor={"#F8F6F6"} >
                                    <Grid>
                                        <Typography variant="h6" color="#0D46A0">{result?.client?.legalName || result?.client?.displayName}</Typography>
                                    </Grid>
                                    {showAddress(result?.client?.address?.billingaddress) && <Grid>
                                        <Typography variant="body2">{getAddress(result?.client?.address?.billingaddress)}</Typography>
                                    </Grid>}

                                    <Grid>
                                        <Typography variant="body2">{result?.client?.mobileNumber}</Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2">{result?.client?.email}</Typography>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

            </Grid>

        </Box>


    );
}

export default BasicDetails;