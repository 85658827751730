import { Alert, Box } from "@mui/material";
import { getGstrClientCompliance } from "api/services/gstr/gstr";
import Loader from "components/Loader";
import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ResType } from "types";

export const GstrClientDataContext = createContext<any>(null);

function GstrClientDataProvider({ children }) {
  const params = useParams();

    const { data, isLoading, error }: ResType = useQuery(
        ["get-client-gstr-comnpliance", params.gstrid],
        getGstrClientCompliance,
    );

  if (isLoading) return <Loader minHeight="60vh" />;

  if (!data || error) {
    return (
      <Box maxWidth={500} margin="auto" mt={5}>
        <Alert severity="error">{error?.message}</Alert>
      </Box>
    );
  }

  return (
    <GstrClientDataContext.Provider value={{ data, isLoading, error }}>
      {children}
    </GstrClientDataContext.Provider>
  );
}

export const useGstrClientData = () => useContext(GstrClientDataContext);

export default GstrClientDataProvider;
