import { Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { getGstFinancialYears } from 'utils/FinancialYears';

const FinancialYearSelector = ({ selectedYear, setSelectedYear }) => {

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const startFinancialYear = currentMonth >= 3 ? currentYear : currentYear - 1;
    const financialYears: string[] = [];
    for (let year = startFinancialYear; year >= 2017; year--) {
        financialYears.push(`${year}-${(year + 1).toString().slice(2)}`);
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel>FY</InputLabel>
                    <Select
                        label="FY"
                        value={selectedYear}
                        onChange={handleYearChange}
                        sx={{ width: 120, mr: 1, height: 40 }}
                    >
                        {getGstFinancialYears().map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}


export default FinancialYearSelector;



