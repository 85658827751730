

import { Box } from "@mui/material";
import { regularRegistrationMenu } from "data/constants";
import useQueryParams from "hooks/useQueryParams";
import { useNavigate } from "react-router-dom";
import { StyledProfileNavColor, StyledProfileNavItem } from "views/ClientProfile/styles";
import Gstr2x from "./ReturnTypes/Gstr-2x";
import Gstr3b from "./ReturnTypes/Gstr-3b";
import Gstr4 from "./ReturnTypes/Gstr-4";
import Cmp08 from "./ReturnTypes/CMP-08";
import Gstr9 from "./ReturnTypes/Gstr-9";
import Gstr9c from "./ReturnTypes/Gstr-9c";
import Iff from "./ReturnTypes/Iff";
import { useEffect } from "react";

function Profile() {
    const navigate = useNavigate();
    const { queryParams } = useQueryParams();
    const active = queryParams.tab;


    useEffect(() => {
        navigate(`?tab=GSTR-1/IFF`);
    }, [])

    return (
        <>
            <Box sx={{ position: "sticky", top: 65, bgcolor: "white", zIndex: 2 }} className="password-card-container">
                <StyledProfileNavColor>
                    {regularRegistrationMenu.map((item, index) => (
                        <StyledProfileNavItem
                            key={index}
                            onClick={() => navigate(`?tab=${item.title}`)}
                            active={active === item.title ? 1 : 0}
                        >
                            {item.title}
                        </StyledProfileNavItem>
                    ))}
                </StyledProfileNavColor>
            </Box>
            <Box px={1} py={1} pb={0} mb={0}>
                {active === "GSTR-1/IFF" && <Iff />}
                {active === "GSTR-2X" && <Gstr2x />}
                {active === "GSTR-3B" && <Gstr3b />}
                {active === "CMP-08" && <Cmp08 />}
                {active === "GSTR-4" && <Gstr4 />}
                {active === "GSTR-9" && <Gstr9 />}
                {active === "GSTR-9C" && <Gstr9c />}
            </Box>
        </>
    );
} 

export default Profile;

