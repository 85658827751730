import { Box } from "@mui/material";
import ClientDashboardAnalytics from "./clientdashboard-analytics";

function BillingOverview() {
  return (
    <Box p={2}>
      <ClientDashboardAnalytics />
    </Box>
  );
}

export default BillingOverview;
