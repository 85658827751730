import { Box, Button } from "@mui/material";
import { getCommonBilling, getCommonUnBilledTaskexport } from "api/services/reports";
import SearchContainer from "components/SearchContainer";
import Table, { ColumnType } from "components/Table";
import { snack } from "components/toast";
import useTitle from "hooks/useTitle";
import _ from "lodash";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import { formattedAmount } from "utils/formattedAmount";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function UnBilledTasks() {
  useTitle("Clients");

  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [data, setData] = useState();
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const clientId = localStorage.getItem('clientId');

  const { data: result, isLoading, error }: ResType = useQuery(
    ['clientinvoiceunbilled', {
      query: 'clientinvoiceunbilled',
      clientId: clientId,
      search,
      pageCount: pageCount,
      offset: page * pageCount
    }],
    getCommonBilling, {
    onSuccess: (res: any) => {
      setData(res?.data);
      setTotalCount(res?.data[0].total_count);
    },
    onError: (err: any) => {
      snack.error(handleError(err));
    }
  });

  const setSearchChange = (e) => {
    setSearch(e);
    setPage(0);
  };

  const { mutate: mutate1 } = useMutation(getCommonUnBilledTaskexport, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "client-unbilled-Tasks.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    const data = {
      query: 'clientinvoiceunbilled',
      clientId: clientId,
      search,
    }
    mutate1(data);
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" >
        <SearchContainer placeHolder="Search by Task ID | Task Name" value={search} onChange={setSearchChange} debounced />
        {/* <Box pt={4}>
          <Button variant="outlined" color="secondary" onClick={handleExport}
            startIcon={<FileDownloadOutlinedIcon />}     >
            Export
          </Button>
        </Box> */}
      </Box>
      <Table
        sx={{ mt: 2, mb: 0, pb: 0 }}
        loading={isLoading}
        data={data || []}
        columns={Columns}
        pagination={{ totalCount, page, setPage, pageCount, setPageCount }}
        onRowClick={(row) => navigate(`/tasks/${row.id}#details`)}
      />
    </>
  );
}

const Columns: Array<ColumnType> = [
  { key: "tasknumber", title: "Task ID" },
  { key: "name", title: "Task Name", width: "240px" },
  {
    key: "status",
    title: "Task Status",
    render: (row: any) => {
      const words = row.status.split("_");
      const titleCaseWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return titleCaseWords.join(" ");
    },
  },
  { key: "pureagent", title: "Pure Agent", render: (row: any) => (formattedAmount(row?.pureagent)) },
  { key: "additional", title: " Additional Charges", render: (row: any) => (formattedAmount(row?.additional)) },
  { key: "fee_amount", title: "Service Fee", render: (row: any) => (formattedAmount(row?.fee_amount)) },
];

export default UnBilledTasks;