import { Box, Button, Typography } from "@mui/material";
import Table from "components/Table";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResType } from "types";
import { getGstFinancialYears } from "utils/FinancialYears";
// import { useClientData } from "context/ClientData";
import { RegularTaxHeadings } from "data/constants";
import {
    getGstrClientRecords,
    getSingleClientReturns,
    syncSingleClientReturns
} from "api/services/clients/gstr-clients";
import EmptyPage from "components/EmptyPage";
import AddInvoice from "../SyncClient";
import FinancialYearSelector from "../YearPeriodSelect";
import Loader from "components/Loader";


function Deductor() {
    const financialYears = getGstFinancialYears();
    const [selectedYear, setSelectedYear] = useState(financialYears[0]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [lodd, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();
    const clientId = localStorage.getItem("clientId");
    // const { data } = useClientData();


    const { data: complianceData, isLoading }: ResType = useQuery(
        [
            "compliance",
            {
                clientId: clientId,
                type: 'tax_deductor',
                rtntype: 'GSTR7',
                selectedYear,

            },
        ],
        getGstrClientRecords, {
        enabled: !!selectedYear,

        onSuccess: (res: any) => {
            setTableData(res?.data)
        },
        onError: (err: any) => {
            if (!err?.error) {
                snack.error(`An error occurred while fetching register data`);
            }
        }
    });

    const { mutate, isLoading: loading } = useMutation(getSingleClientReturns, {
        onSuccess: (res) => {
            setTimeout(() => {
                queryClient.invalidateQueries("compliance");
                snack.success("Successfully synced");

            }, 200)

        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        },
    });

    const { mutate: syncMutate, isLoading: load } = useMutation(syncSingleClientReturns, {
        onSuccess: (res) => {
            setLoad(false);
            setTimeout(() => {
                queryClient.invalidateQueries("compliance");
                snack.success("Successfully synced");
            }, 200)
        },
        onError: (err: any) => {
            snack.error(err.response.data.message);
        }
    })

    // const syncData = async () => {
    //     if (!selectedYear) return snack.error('Select Financial Year');
    //     const filteredRetPrdValues = tableData.filter(item => item['retPrd'] !== null && item['retPrd'].length === 6).map(item => item['retPrd']);
    //     if (filteredRetPrdValues.length === 12) return snack.success("One-Time Sync! Your GST Returns data has been synced and is ready to use. Since there's no option to revise past GST returns, your data will stay synced.");
    //     setLoad(true);
    //     syncMutate({
    //         financialYear: selectedYear,
    //         type: 'tax_deductor',
    //         clientId: data?.data?.id,
    //         gstNumber: data?.data?.gstNumber,
    //         gstrRegisterId: tableData[0]?.gstrRegisterId,
    //         filledMonths: filteredRetPrdValues
    //     })
    // };
    const onClickSync = () => {
        setOpen(true)
    };

    if (isLoading || load) return <Loader />

    return (complianceData?.data == "Not-synchronized" ? (<>
        <>
            <EmptyPage
                minHeight="50vh"
                title={RegularTaxHeadings.GST_NOT_VERIFIED}
                {...(true && {
                    btn2Title: "Sync To Gst Returns",
                    btn2Action: () => onClickSync(),
                })}
            />
            {/* <AddInvoice open={open} setOpen={setOpen} data={{
                financialYear: selectedYear,
                type: 'tax_deductor',
                clientId: data?.data?.id,
                rtntype: 'GSTR7',
                gstNumber: data?.data?.gstNumber
            }} /> */}
        </>

    </>) : (

        <Box p={3} >
            <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <FinancialYearSelector
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                    />&emsp;
                </Box>
            </Box>
            <Table
                sx={{ mt: 3 }}
                loading={isLoading || lodd || loading}
                data={tableData || []}
                columns={gstReturncolumns}
                heading={RegularTaxHeadings.GSTR7}
            />
        </Box >
    )
    );




}



export const gstReturncolumns = [
    {
        key: "legalName",
        title: "Legal Name",
    },
    { key: "tradeName", title: "Trade Name" },
    {
        key: "frequency",
        title: "Period",
    },
    {
        key: "status",
        title: "Status",
        render: (row: any) => (<Typography color={row?.status === "Filed" ? "Green" : "Red"}>{row?.status}</Typography>)
    },
    {
        key: "dof",
        title: "Date of Filing",
    },
    {
        key: "arn",
        title: "ARN",
    },


];



export default Deductor;
