import "./App.css";
import RoutesContainer from "./RoutesContainer";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UserProfileProvider from "context/UserProfile";
import ConfirmDialogProvider from "context/ConfirmDialog";
import MenuPopoverProvider from "context/MenuPopover";
import PermissionsProvider from "context/PermissionsProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmDialogProvider>
            <MenuPopoverProvider>
              <PermissionsProvider>
                <UserProfileProvider>
                  <RoutesContainer />
                </UserProfileProvider>
              </PermissionsProvider>
            </MenuPopoverProvider>
          </ConfirmDialogProvider>
        </LocalizationProvider>
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
}

export default App;
