import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Divider, Grid, Radio, styled, Typography } from "@mui/material";
import { LinkRouter } from "components/BreadCrumbs";
import RouterLink from "components/RouterLink";
import useTitle from "hooks/useTitle";
import { useLocation, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { ResType } from "types";
import { useQuery } from "react-query";

import { StyledContainer, StyledText1, StyledText2, StyledTitle } from "views/atom-pro/styles";
import Loader from "components/Loader";
import { getIncomeTaxEproceedingNoticeFyi } from "api/services/automation/income-tax";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface StyledSpanProps {
  bgColor?: string; // Define bgColor prop here
}

const StyledSpan = styled("span")<StyledSpanProps>(({ theme, bgColor }) => ({
  backgroundColor: bgColor || "#fffff",
  padding: "3px",
  fontSize: "16px",
  fontWeight: "500",
  borderRadius: "5px",
  color: "#222222",
}));

const handleItemClick = (fileName, type) => {
  const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
  const amazon = type == "AMAZON" || type == null
  const url = amazon ? (urlStart + fileName) : fileName?.key;
  window.open(url, '_blank');
};

const getFileName = (filePath, type) => {
  // Split the filePath by '/' and get the last part which is the file name
  if (type == 'AMAZON' || type == null) {
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  } else if (type == 'MICROSOFT') {
    const parts = filePath['name'].split('/');
    return parts[parts.length - 1];
  };
  return null

};

function FYINoticeView() {
  useTitle("e-Proceedings (For Your Information) Notice");
  const params = useParams();
  const location = useLocation();
  const [state, setInitialState] = useState<any>({});

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleToggle = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { data, isLoading: demandIsLoading }: ResType = useQuery(
    ["e-proceeding-notice", params.itemId],
    getIncomeTaxEproceedingNoticeFyi,
    {
      enabled: !!params.itemId,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setInitialState(data?.data);
    }
  }, [data]);

  const generateAssessmentYear = (assessmentYear) => {
    if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
      return "NA";
    } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
      const year = parseInt(assessmentYear);
      const nextYear = (year + 1).toString().slice(-2);
      return `AY ${year}-${nextYear}`;
    } else {
      return assessmentYear;
    }
  };

  const backgroundColors = [
    "#ffffcc",
    "#ccffcc",
    "#ffcccc",
    "#ccccff",
    "#ffccff",
    "#f0f8ff",
    "#f0fff0",
    "#f5f5dc",
    "#f5f5f5",
    "#f5fffa",
  ];
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    return backgroundColors[randomIndex];
  };

  if (Object.keys(state).length === 0) return <Typography variant="body2">No data</Typography>;

  const getFileName = (filePath, type) => {
    // Split the filePath by '/' and get the last part which is the file name
    if (type == "AMAZON" || type == null) {
      const parts = filePath.split("/");
      return parts[parts.length - 1];
    } else if (type == "MICROSOFT") {
      const parts = filePath["name"].split("/");
      return parts[parts.length - 1];
    }
    return null;
  };

  const handleItemClick = (fileName, type) => {
    const urlStart = `https://jss-vider-bucket.s3.ap-south-1.amazonaws.com/`;
    const amazon = type == "AMAZON" || type == null;
    const url = amazon ? urlStart + fileName : fileName?.key;
    window.open(url, "_blank");
  };



  if (demandIsLoading) return <Loader />;
  return (
    <>
      <Box height="75vh" overflow="auto" mb={3}>
        <Box>
          <Typography
            sx={{ marginBottom: "5px", color: "#2F343A", paddingLeft: "10px" }}
            variant="subtitle1"
          >
            Proceeding Name: {state?.proceedingName}
          </Typography>
          <Typography
            sx={{ marginBottom: "5px", color: "#7C848E", paddingLeft: "10px" }}
            variant="subtitle2"
          >
            Document Identification No:{state?.documentIdentificationNumber}
          </Typography>
          <Typography
            sx={{ marginBottom: "10px", color: "#7C848E", paddingLeft: "10px" }}
            variant="subtitle2"
          >
            Document Reference Id:{state?.documentReferenceId}
          </Typography>

          <Grid item xs={12} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    PAN
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.pan}
                  </StyledSpan>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    Assessment Year
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {generateAssessmentYear(state?.assesmentYear)}
                  </StyledSpan>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    ITR Type
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.itrType ? state?.itrType : "--"}
                  </StyledSpan>
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    Issued On
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.issuedOn ? moment(state?.issuedOn).format("DD MMM YYYY") : "--"}
                  </StyledSpan>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    Notice Section
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.noticeSection ? state?.noticeSection : "--"}
                  </StyledSpan>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    Response Due Date
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.responseDueDate
                      ? moment(state.responseDueDate).format("DD MMM YYYY")
                      : "--"}
                  </StyledSpan>
                </Box>
              </Grid>
              {/* <Grid item xs={3}>
                <Box
                  style={{
                    border: "1.5px solid #d1d1e0",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "7px",
                  }}
                >
                  <StyledText1 variant="h6" color="primary" mb={1}>
                    Last Response Submitted On
                  </StyledText1>
                  <StyledSpan
                  // bgColor={getRandomColor()}
                  >
                    {state?.lastResponseSubmittedOn
                      ? moment(state.lastResponseSubmittedOn).format("DD MMM YYYY")
                      : "--"}
                  </StyledSpan>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>

          <Box>
            <Box display="flex" p={1} pl={2} pr={2} pb={2}>
              <Typography sx={{ color: "#2F2F2F", fontWeight: 600 }} variant="body2">
                Description :-
              </Typography>
              <Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ backgroundColor: "#EAFFE7", fontWeight: 600 }}
                >
                  {state?.description}
                </Box>
              </Typography>
            </Box>

            <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
              <Typography variant="subtitle2">Notice Response</Typography>
            </Box>
            {state?.responses.map((item, index) => (
              <Accordion expanded={expanded === `panel${index}`} onChange={handleToggle(`panel${index}`)} key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}bh-content`} id={`panel${index}bh-header`}>
                  <Typography variant="h6">Response {index + 1},  {item?.submittedOn
                    ? moment(item?.submittedOn).format("DD MMM YYYY HH:mm")
                    : "--"},  {item?.responseType === "FR" ? "Full Response" : item?.responseType === "PR" ? "Partial Response" : "--"}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box display="flex" gap={2}>
                    <Grid item xs={12} p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "10px",
                              marginBottom: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <StyledText1 variant="h6" color="primary" mb={1}>
                              Response/Remarks
                            </StyledText1>
                            <StyledSpan
                            // bgColor={getRandomColor()}
                            >
                              {item.remarks ? item?.remarks : "--"}
                            </StyledSpan>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "10px",
                              marginBottom: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <StyledText1 variant="h6" color="primary" mb={1}>
                              Remark Submission On
                            </StyledText1>
                            <StyledSpan
                            // bgColor={getRandomColor()}
                            >
                              {item?.submittedOn
                                ? moment(item?.submittedOn).format("DD MMM YYYY HH:mm")
                                : "--"}
                            </StyledSpan>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            style={{
                              border: "1.5px solid #d1d1e0",
                              padding: "10px",
                              marginBottom: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <StyledText1 variant="h6" color="primary" mb={1}>
                              Response Type
                            </StyledText1>
                            <StyledSpan
                            // bgColor={getRandomColor()}
                            >
                              {item?.responseType === "FR" ? "Full Response" : item?.responseType === "PR" ? "Partial Response" : "--"}
                            </StyledSpan>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
                            <Typography variant="subtitle2">Attachments</Typography>
                          </Box>
                       
                          <Grid container spacing={1}>
                            {item?.attachments &&
                              item?.attachments.map((detail: any, index: number) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                  <Box display="flex" textAlign="left" alignItems="center">
                                    <Typography style={{ padding: "3px", margin: "2px" }}>
                                      {index + 1}.
                                    </Typography>
                                    <div
                                      key={index}
                                      style={{
                                        cursor: "pointer",
                                        padding: "3px",
                                        margin: "2px",
                                        border: "1px solid #ccc",
                                        borderRadius: "5px",
                                        color: "blue",
                                      }}
                                      onClick={() => handleItemClick(detail, item?.storageSystem)}
                                    >
                                      {getFileName(detail, item?.storageSystem)}
                                    </div>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}

            <Box padding={1} sx={{ backgroundColor: "#F6F6F6" }}>
              <Typography variant="subtitle2">Notice/Letter</Typography>
            </Box>

            <div>
              {state?.noticeLetters &&
                state?.noticeLetters.map((detail: any, index: number) => (
                  <Box display="flex" textAlign="center">
                    <Typography style={{ padding: "5px", margin: "5px" }}>{index + 1}.</Typography>
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                        margin: "5px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        color: "blue",
                      }}
                      onClick={() => handleItemClick(detail, state?.storageSystem)}
                    >
                      {getFileName(detail, state?.storageSystem)}
                    </div>
                  </Box>
                ))}
            </div>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FYINoticeView;
