import { Box, Typography } from "@mui/material"
import { getClientForm } from "api/services/automation/income-tax";
import Table from "components/Table";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ResType } from "types";
import { StyledSpan } from "./styles";
import { useState } from "react";

const ClientForm = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [page, setPage] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(10);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { data, isLoading, error }: ResType = useQuery(
        ["get-form-data", params.incometaxid, {  limit: pageCount, offset: page * pageCount }],
        getClientForm,
        {
          onSuccess: (res) => {
            setTotalCount(res?.data?.count);
          },
        }
      );


    function calculateAssessmentYear(startYear) {
        const endYear = parseInt(startYear) + 1;
        return startYear + "-" + endYear.toString().slice(2);
    }

    console.log('data',data)

    let columns = [
        {
            key: "id",
            title: "FY / AY",
            render: (row: any) => {
                return (
                    <Box color="#ffffff">
                        <StyledSpan bgColor="#005580">{row?.refYear ? `${row?.refYearType} ${calculateAssessmentYear(row?.refYear)}` : ""}</StyledSpan>

                    </Box>
                )
            },
        },
        {
            key: "formDesc",
            title: "Form Name",
        },
        {
            key: "ackNum",
            title: "Acknowledgement #",
        },
        {
            key: "ackDt",
            title: "Filing Date",
        },
        {
            key: "filingTypeCd",
            title: "Filing Type",
        },
        

    ];

    const handleRowClick = (v: any) => {
        navigate(`/atom-pro/income-tax/incometax/${params.incometaxid}/form/${v?.id}`)
    }
    return (
        <Box>
            {data && data?.data.count > 0 ?
                <>
                    <Table
                        // selection={{ selected, setSelected }}
                        pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
                        data={data?.data?.result || []}
                        columns={columns}
                        loading={isLoading}
                        onRowClick={handleRowClick}
                    />
                </> : <>
                    <Box textAlign="center" mt={20}>
                        <Typography variant="subtitle1" color="rgba(0,0,0,0.5)">
                            No Data present
                        </Typography>
                    </Box>
                </>}

        </Box>
    )
}

export default ClientForm